@use '/src/styles/base/variables' as variable;
@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;
@use '/src/styles/base/typography' as typography;

.getting_started_container {
  width: 100%;

  .getting_started {
    .getting_started_todo {
      @include layout.flex($justify: flex-start, $align: flex-start);
      flex-direction: column;
      gap: 1.25rem;
    }
  }

  .todo_container {
    width: 100%;
    @include layout.flex($justify: space-between, $align: center);
    gap: 0.5rem;

    @include breakpoint.media('<tablet') {
      @include layout.flex($justify: flex-start, $align: flex-start);
      flex-direction: column;
    }

    .todo_task {
      @include layout.flex($justify: flex-start, $align: center);
      gap: 0.75rem;
      width: 100%;
    }

    .todo_task_button {
      text-wrap: nowrap;

      @include breakpoint.media('<tablet') {
        margin-left: 2rem;
      }
    }
  }
}
