@use "/src/styles/base/_variables.scss" as variable;
@use "/src/styles/base/_typography.scss" as typography;
@use "/src/styles/mixins/_layout" as layout;

.click-to-copy {
  position: relative;

  .copied-tooltip-content {
    color: variable.$white;
    background-color: variable.$background-background-neutral-bold-pressed;
    padding: 0.55rem 0.89rem;
    text-align: center;
    min-width: fit-content;
    width: max-content;
    max-width: 320px;
    font-weight: 400;
    height: fit-content;
    border-radius: variable.$card-radius;
    position: absolute;
    bottom: -100%;
    left: 50%;
    transform: translate(-50%, 50%);
    transition: opacity 0.3s ease-in-out;
    @extend .xxs;

    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      transform: rotate(180deg);
      border-color: variable.$background-background-neutral-bold-pressed transparent transparent transparent;
    }
  }
}
