@use '/src/styles/base/variables' as variable;
@use '/src/styles/base/_typography' as typography;

.input-field-container .react-select_container {
  width: 100%;

  .styled-checkbox-container {
    display: inline-flex;
  }
}

.react-select__placeholder {
  @extend .sm;
  color: variable.$grey-400 !important;
  font-weight: 400;
}
.react-select__control {
  @extend .xs;
  border: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border-radius: variable.$input-radius !important;
}

.react-select__control--is-focused {
  border: 0 !important;
  background-color: transparent !important;
}

.react-select__input-container {
  padding: 0 !important;
  margin: 0 !important;
}

.react-select__value-container {
  padding: 0.75rem 0.75rem !important;
}

.react-select__menu {
  z-index: 2 !important;
}

.react-select__menu-list {
  overflow-x: hidden;
}

.react-select__option {
  display: flex !important;
  align-items: center !important;
}

.react-select__option--is-selected {
  background-color: transparent !important;
  color: variable.$black !important;
}

.react-select__option--is-focused {
  background-color: transparent !important;
  cursor: pointer !important;
}

.react-select__indicator {
  padding-right: 0.5rem !important;
  padding-left: 0.25rem !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__multi-value {
  // margin: 0 !important;
  padding: 0.125rem;
  border-radius: 0.25rem !important;
  min-width: fit-content !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.react-select__value-container--is-multi {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  overflow-x: auto !important;
}

.react-select__option,
.react-select__menu-list {
  @extend .xs;
}

.multivalue-remove-icon {
  display: inline-flex;
  width: 0.75rem;
  height: 0.75rem;
  color: variable.$grey-800;

  &:hover {
    color: variable.$error-500;
  }
}

.css-12a83d4-MultiValueRemove:hover {
  background-color: transparent !important;
}

.react-select_container .option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.react-select_container .option-label {
  display: flex;
  align-items: center;
  gap: 0.25rem
}

.react-select__single-value {
  font-size: 0.875rem !important;
  line-height: 1.22rem !important;
}

.select-checkbox {
  pointer-events: none;
}
