@use './colors' as colors;

//layout
$topbar-height: 4.44rem;
$sidebar-width: 15.56rem;
$sidebar-width-mobile: 13.56rem;
$sidebar-collapsed-width: 4rem;
$sidebar-padding: 1.33rem;
$sidebar-padding-mobile: 0.89rem;
$body-padding-mobile: 1rem;
$body-padding-tablet: 1.11rem;
$body-padding-desktop: 1.5rem;
$body-horizontal-padding-desktop: 2rem;

//components variables
$card-padding-top: 1rem;
$card-padding-left: 1.25rem;

////// Colors ///////////
$black: #141414;
$white: #ffffff;
$primary-col: #213f7d;
$secondary-col: #57d4d4;
$success: #18a957;
$info: #3cb2ff;
$warning: #ffbb38;
$error: #df1642;
$jsonBg: #faffff;

//Brand Color
$stability-col: $primary-col;
$quality-col: $secondary-col;
$blue-col: $info;
$green-col: $success;
$yellow-col: $warning;
$red-col: $error;
$maroon-col: #c0667d;
$purple-col: #8236cc;
$sophisticated-col: $white;
$powerful-col: #000000;

//Primary Color Shade and Tints
$primary-col-950: #03060c;
$primary-col-900: #070d19;
$primary-col-800: #0d1932;
$primary-col-700: #14264b;
$primary-col-600: #1a3264;
$primary-col-500: $primary-col;
$primary-col-400: #4d6597;
$primary-col-300: #7a8cb1;
$primary-col-200: #a6b2cb;
$primary-col-100: #d3d9e5;
$primary-col-50: #e9ecf2;

//Secondary Color Shade and Tints
$secondary-col-950: #091515;
$secondary-col-900: #112a2a;
$secondary-col-800: #235555;
$secondary-col-700: #347f7f;
$secondary-col-600: #46aaaa;
$secondary-col-500: $secondary-col;
$secondary-col-400: #79dddd;
$secondary-col-300: #9ae5e5;
$secondary-col-200: #bceeee;
$secondary-col-100: #ddf6f6;
$secondary-col-50: #eefbfb;

//Success Shade and Tints
$success-900: #021109;
$success-800: #052211;
$success-700: #0a4423;
$success-600: #11763d;
$success-500: $success;
$success-400: #5dc389;
$success-300: #a3ddbc;
$success-200: #d1eedd;
$success-100: #e8f6ee;

//Info Shade and Tints
$info-900: #098ad5;
$info-800: #0c2433;
$info-700: #184766;
$info-600: #2a7db3;
$info-500: $info;
$info-400: #77c9ff;
$info-300: #b1e0ff;
$info-200: #d8f0ff;
$info-100: #ecf7ff;

//Warning Shade and Tints
$warning-900: #191306;
$warning-800: #33250b;
$warning-700: #664b16;
$warning-600: #b38327;
$warning-500: $warning;
$warning-400: #ffcf74;
$warning-300: #ffe4af;
$warning-200: #fff1d7;
$warning-100: #fff8eb;

//Error Shade and Tints
$error-900: #160207;
$error-800: #2d040d;
$error-700: #59091a;
$error-600: #9c0f2e;
$error-500: $error;
$error-400: #e95c7b;
$error-300: #f2a2b3;
$error-200: #f9d0d9;
$error-100: #fce8ec;

//White Opacity
$white-90: rgba($white, 0.9);
$white-80: rgba($white, 0.8);
$white-70: rgba($white, 0.7);
$white-60: rgba($white, 0.6);
$white-50: rgba($white, 0.5);
$white-40: rgba($white, 0.4);
$white-30: rgba($white, 0.3);
$white-20: rgba($white, 0.2);
$white-10: rgba($white, 0.1);
$white-5: rgba($white, 0.05);
$white-1: rgba($white, 0.01);

//Grey
$grey-950: #212121;
$grey-900: #323232;
$grey-800: #424242;
$grey-700: #616161;
$grey-600: #757575;
$grey-500: #9e9e9e;
$grey-400: #bdbdbd;
$grey-300: #e0e0e0;
$grey-200: #eeeeee;
$grey-100: #f5f5f5;
$grey-50: #fbfbfb;

//Additional Colors
$sise-dark-blue: #00194f;

//Modal Background
$modal-background: #8590a280;

//Gradients
$new-york-gradient: linear-gradient(270deg, #ace0f9 0%, #fff1eb 100%);
$light-mist-gradient: linear-gradient(270deg, #e8e8e8 0%, #ffffff 100%);
$scooter-gradient: linear-gradient(270deg, #5b86e5 0%, #36d1dc 100%);
$fiesta-gradient: linear-gradient(270deg, #105efb 0%, #199afb 100%);
$lawrencium-gradient: linear-gradient(270deg, #24243e 0%, #0f0c29 100%);
$adjutor-gradient: linear-gradient(360deg, #023784 18.02%, #36d1dc 125.11%);

//shadow
$shadow-20:
  0px 0px 1px rgba(48, 49, 51, 0.05),
  0px 1px 3px rgba(48, 49, 51, 0.1);

$shadow-40:
  0px 8px 12px 0px #091e4226,
  0px 0px 1px 0px #091e4226;

$shadow-60:
  0px 0px 1px rgba(48, 49, 51, 0.05),
  0px 4px 8px rgba(48, 49, 51, 0.1);

$shadow-80:
  0px 0px 1px rgba(48, 49, 51, 0.05),
  0px 8px 16px rgba(48, 49, 51, 0.1);

$shadow-100:
  0px 0px 1px rgba(48, 49, 51, 0.05),
  0px 16px 24px rgba(48, 49, 51, 0.1);

//// Roundness ////
$card-radius: 0.5rem;
// $card-radius: 0.25rem;
$input-radius: 0.375rem;
$button-radius: 0.25rem;
$rounded-full: 9999px;

/// Z Index ////
$dropdown-index: 9;
$topbar-index: 10;
$sidebar-index: 11;
$sidepane-backdrop-z-index: 20;
$sidepane-z-index: 21;
$sidepane-header-z-index: 21;

//// Modal ////
$modal-header-text-size: 1.25rem;

$background-background-avatar-default: colors.$brand-tiffany-blue-tiffanyblue-200;
$background-background-brand-bold-default: colors.$brand-tiffany-blue-tiffanyblue-800;
$background-background-brand-bold-hovered: colors.$brand-tiffany-blue-tiffanyblue-900;
$background-background-brand-bold-pressed: colors.$brand-tiffany-blue-tiffany-blue1000;
$background-background-button-default: colors.$brand-blue-blue-200;
$background-background-button-hovered: colors.$brand-blue-blue-300;
$background-background-button-pressed: colors.$brand-blue-blue-400;
$background-background-danger-bold-default: colors.$semantics-red-red-700;
$background-background-danger-bold-hovered: colors.$semantics-red-red-800;
$background-background-danger-bold-pressed: colors.$semantics-red-red-900;
$background-background-danger-default: colors.$semantics-red-red-100;
$background-background-danger-hovered: colors.$semantics-red-red-200;
$background-background-danger-pressed: colors.$semantics-red-red-300;
$background-background-default: colors.$neutrals-light-mode-solid-neutral-0;
$background-background-disabled: colors.$neutrals-light-mode-solid-neutral-100;
$background-background-info-bold-default: colors.$brand-blue-blue-700;
$background-background-info-bold-hovered: colors.$brand-blue-blue-800;
$background-background-info-bold-pressed: colors.$brand-blue-blue-900;
$background-background-info-default: colors.$brand-blue-100;
$background-background-info-hovered: colors.$brand-blue-blue-200;
$background-background-info-pressed: colors.$brand-blue-blue-300;
$background-background-input-default: colors.$neutrals-light-mode-solid-neutral-0;
$background-background-input-hovered: colors.$neutrals-light-mode-solid-neutral-100;
$background-background-input-pressed: colors.$neutrals-light-mode-solid-neutral-0;
$background-background-magenta-bold-default: colors.$semantics-magenta-magenta-700;
$background-background-magenta-bold-hovered: colors.$semantics-magenta-magenta-800;
$background-background-magenta-bold-pressed: colors.$semantics-maroon-maroon-900;
$background-background-magenta-default: colors.$semantics-magenta-magenta-100;
$background-background-magenta-hovered: colors.$semantics-magenta-magenta-200;
$background-background-magenta-pressed: colors.$semantics-magenta-magenta-300;
$background-background-maroon-bold-default: colors.$semantics-maroon-maroon-700;
$background-background-maroon-bold-hovered: colors.$semantics-maroon-maroon-800;
$background-background-maroon-bold-pressed: colors.$semantics-maroon-maroon-900;
$background-background-maroon-default: colors.$semantics-maroon-maroon-100;
$background-background-maroon-hovered: colors.$semantics-maroon-maroon-200;
$background-background-maroon-pressed: colors.$semantics-maroon-maroon-300;
$background-background-neutral-bold-default: colors.$neutrals-light-mode-solid-neutral-800;
$background-background-neutral-bold-hovered: colors.$neutrals-light-mode-solid-neutral-900;
$background-background-neutral-bold-pressed: colors.$neutrals-light-mode-solid-neutral1100;
$background-background-neutral-default: colors.$neutrals-light-mode-alpha-neutral-200a;
$background-background-neutral-hovered: colors.$neutrals-light-mode-alpha-neutral-300a;
$background-background-neutral-pressed: colors.$neutrals-light-mode-alpha-neutral-400a;
$background-background-purple-bold-default: colors.$semantics-purple-purple-700;
$background-background-purple-bold-hovered: colors.$semantics-purple-purple-800;
$background-background-purple-bold-pressed: colors.$semantics-purple-purple-900;
$background-background-purple-default: colors.$semantics-purple-purple-100;
$background-background-purple-hovered: colors.$semantics-purple-purple-200;
$background-background-purple-pressed: colors.$semantics-purple-purple-300;
$background-background-success-bold-default: colors.$semantics-green-green-700;
$background-background-success-bold-hovered: colors.$semantics-green-green-800;
$background-background-success-bold-pressed: colors.$semantics-green-green-900;
$background-background-success-default: colors.$semantics-green-green-100;
$background-background-success-hovered: colors.$semantics-green-green-200;
$background-background-success-pressed: colors.$semantics-green-green-300;
$background-background-teal-bold-default: colors.$semantics-teal-teal-700;
$background-background-teal-bold-hovered: colors.$semantics-teal-teal-800;
$background-background-teal-bold-pressed: colors.$semantics-teal-teal-900;
$background-background-teal-default: colors.$semantics-teal-teal-100;
$background-background-teal-hovered: colors.$semantics-teal-teal-200;
$background-background-teal-pressed: colors.$semantics-teal-teal-300;
$background-background-tifannyblue-bold-default: colors.$brand-tiffany-blue-tiffanyblue-700;
$background-background-tifannyblue-bold-hovered: colors.$brand-tiffany-blue-tiffanyblue-800;
$background-background-tifannyblue-bold-pressed: colors.$brand-tiffany-blue-tiffanyblue-900;
$background-background-tifannyblue-bold-disabled: colors.$brand-tiffany-blue-tiffanyblue-200;
$background-background-tifannyblue-default: colors.$brand-tiffany-blue-tiffanyblue-200;
$background-background-tifannyblue-hovered: colors.$brand-tiffany-blue-tiffanyblue-300;
$background-background-tifannyblue-pressed: colors.$brand-tiffany-blue-tiffanyblue-400;
$background-background-warning-bold-default: colors.$semantics-orange-orange-700;
$background-background-warning-bold-hovered: colors.$semantics-orange-orange-800;
$background-background-warning-bold-pressed: colors.$semantics-orange-orange-900;
$background-background-warning-default: colors.$semantics-yellow-100;
$background-background-warning-hovered: colors.$semantics-yellow-yellow-200;
$background-background-warning-pressed: colors.$semantics-yellow-yellow-300;
$blanket-blanket-default: colors.$neutrals-light-mode-solid-neutral-500;
$border-border-blue: colors.$brand-blue-blue-600;
$border-border-brand-default: colors.$brand-tiffany-blue-tiffanyblue-700;
$border-border-brand-hover: colors.$brand-tiffany-blue-tiffanyblue-800;
$border-border-default: colors.$neutrals-light-mode-alpha-neutral-300a;
$border-border-disabled: colors.$neutrals-light-mode-alpha-neutral-200a;
$border-border-focused: colors.$brand-tiffany-blue-tiffanyblue-600;
$border-border-green: colors.$semantics-green-green-600;
$border-border-hover: colors.$neutrals-light-mode-alpha-neutral-400a;
$border-border-input: colors.$neutrals-light-mode-solid-neutral-300;
$border-border-input-hovered: colors.$neutrals-light-mode-solid-neutral-400;
$border-border-inverse: colors.$neutrals-light-mode-solid-neutral-0;
$border-border-magenta: colors.$semantics-magenta-magenta-600;
$border-border-maroon: colors.$semantics-maroon-maroon-600;
$border-border-orange: colors.$semantics-orange-orange-600;
$border-border-purple: colors.$semantics-purple-purple-600;
$border-border-red: colors.$semantics-red-red-600;
$border-border-selected: colors.$brand-blue-blue-600;
$border-border-teal: colors.$semantics-teal-teal-600;
$icon-icon-blue: colors.$brand-blue-blue-700;
$icon-icon-brand: colors.$brand-blue-blue-900;
$icon-icon-button-brand: colors.$brand-tiffany-blue-tiffanyblue-700;
$icon-icon-danger: colors.$semantics-red-red-700;
$icon-icon-default: colors.$neutrals-light-mode-solid-neutral-800;
$icon-icon-disabled: colors.$neutrals-light-mode-alpha-neutral-400a;
$icon-icon-inverse: colors.$neutrals-light-mode-solid-neutral-0;
$icon-icon-magenta: colors.$semantics-magenta-magenta-700;
$icon-icon-maroon: colors.$semantics-maroon-maroon-600;
$icon-icon-purple: colors.$semantics-purple-purple-700;
$icon-icon-subtle: colors.$neutrals-light-mode-solid-neutral-700;
$icon-icon-success: colors.$semantics-green-green-600;
$icon-icon-teal: colors.$semantics-teal-teal-700;
$icon-icon-warning: colors.$semantics-orange-orange-600;
$link-link-default: colors.$brand-tiffany-blue-tiffanyblue-700;
$link-link-hover: colors.$brand-tiffany-blue-tiffanyblue-800;
$link-link-pressed: colors.$brand-tiffany-blue-tiffanyblue-900;
$link-link-visited: colors.$semantics-purple-purple-800;
$text-text-blue: colors.$brand-blue-blue-800;
$text-text-brand-blue: colors.$brand-blue-blue-900;
$text-text-button-brand-tiffanyblue: colors.$brand-tiffany-blue-tiffanyblue-700;
$text-text-disabled: colors.$neutrals-light-mode-alpha-neutral-400a;
$text-text-green: colors.$semantics-green-green-800;
$text-text-inverse: colors.$neutrals-light-mode-solid-neutral-0;
$text-text-link: colors.$brand-blue-blue-600;
$text-text-magenta: colors.$semantics-magenta-magenta-800;
$text-text-maroon: colors.$semantics-maroon-maroon-800;
$text-text-orange: colors.$semantics-orange-orange-800;
$text-text-primary: colors.$neutrals-light-mode-solid-neutral-1000;
$text-text-purple: colors.$semantics-purple-purple-800;
$text-text-red: colors.$semantics-red-red-800;
$text-text-secondary: colors.$neutrals-light-mode-solid-neutral-800;
$text-text-teal: colors.$semantics-teal-teal-800;
$text-text-tetiary: colors.$neutrals-light-mode-solid-neutral-700;
$semantics-yellow-yellow-500: colors.$semantics-yellow-yellow-500;

//Presets
$text-color: $text-text-secondary;
$body-bg: $grey-50;
