@use '/src/styles/base/variables' as variable;
@import '../login/_login.scss';

.sign-up-page {
  @extend .login-page;

  .resend-email-btn {
    margin-top: 1rem;
    width: 100%;
  }

  .link-wrap {
    border: 1px solid #39cdcc;
    font-size: 0.78rem;
    font-weight: 900;
    border-radius: 0.44rem;
    color: #545f7d;
    text-align: center;
    padding: 0.77777778rem 0;
    transition: 0.3s;
  }

  .link-wrap:hover {
    background: #39cdcc;
  }

  .link-wrap:active {
    opacity: 0.8;
  }

  .subtext {
    margin-top: 2.056rem;
    margin-bottom: 1.78rem;
  }

  .link {
    width: 100%;
    // border: 1px solid red;
  }
  .align {
    text-align: center;
  }

  .select-heading {
    margin-bottom: 1.389rem;
  }

  .color {
    color: #545f7d;
    font-weight: 900;
    font-size: 0.78rem;
  }
}
