@use '/src/styles/base/variables' as variable;
@use '/src/styles/base/typography.scss' as typography;
@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;
@use '/src/pages/business/apps/app-details/app-details' as app-style;

.models-page {
  .create-modal-button {
    button {
      padding: 0.5rem 0.75rem;
      @extend p;
      .icon {
        @include layout.flex($justify: center, $align: center);
        width: 1.25rem;
      }
    }
  }

  .analytics-grid {
    display: grid;
    gap: 1.11rem;
    grid-template-columns: repeat(3, 1fr);

    @include breakpoint.media('<desktop') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint.media('<tablet') {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }

  .analytics-card {
    @include layout.flex($justify: space-between);
    flex-direction: column;

    .card-heading {
      @include layout.flex($justify: center, $align: center);
      column-gap: 0.89rem;
      flex-wrap: nowrap;
      @extend .sm;
      font-weight: bold;
    }

    .value {
      color: variable.$sise-dark-blue;
    }

    .footer {
      @extend .xs;

      .progress {
        color: variable.$grey-500;
      }

      .monthly-progress {
        @extend .tiny;
        @include layout.flex($justify: center, $align: center);
        .icon {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 0.78rem;
          margin-right: 0.22rem;
        }

        &[data-variant='increase'] {
          color: variable.$success-500;
        }

        &[data-variant='decrease'] {
          color: variable.$error-500;
          .icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .models-table {
    margin-top: 1.61rem;

    .row-indicator-chip[data-status='declined'] {
      color: variable.$error-500;
    }
    .row-indicator-chip[data-status='pending'] {
      color: variable.$warning-500;
    }
    .row-indicator-chip[data-status='active'] {
      color: variable.$success-500;
    }
  }
}
