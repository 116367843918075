@use '/src/styles/base/variables' as variable;
@use '/src/styles/base/typography' as typography;
@use '/src/styles/mixins/breakpoints' as breakpoints;
@use '/src/styles/mixins/layout' as layout;

.mobile-table-container {
  .table-heading {
    padding: 0 0 0.89rem;
    @include layout.flex($align: center, $justify: space-between);

    .title {
      font-weight: bold;
    }

    & + hr {
      border-top: 0.0567rem solid variable.$grey-100;
      background-color: variable.$grey-100;
      margin-bottom: 0.67rem;
    }
  }

  .mobile-table-body {
    &[data-loading='true'] {
      position: relative;
      height: 100px;
      .table-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .mobile-table-row {
    &:not(:first-child) {
      padding-top: 0.67rem;
      margin-top: 0.67rem;
      border-top: 0.056rem solid variable.$grey-300;
    }

    .mobile-table-row-body {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 1rem;
      color: variable.$black;
      @extend .sm;

      .field-container {
        @include layout.flex($align: center);
        gap: 0.44rem;
        height: fit-content;
        white-space: pre-wrap;
      }

      .field-title {
        @extend .sm;
        font-weight: 400;
      }
    }

    .dropdown-field {
      @include layout.flex($justify: flex-end);
      .dropdown-menu {
        transform-origin: top right;
        color: variable.$black;

        &:last-child {
          bottom: 0;
          right: 50%;
          transform-origin: bottom right;
        }
      }

      &[data-rowicon='true'] {
        @include layout.flex($justify: space-between);

        .row-indicator-chip {
          padding-left: 0;
        }
      }
    }
  }
}
