@use '/src/styles/base/variables' as variable;

.custom_toast_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  gap: 0.75rem;
  border-radius: 0.375rem;

  //   background-color: pink;
}

.custom_toast_info {
  background-color: #e9f2ff;
  color: #0055cc;
}
.custom_toast_error {
  background-color: variable.$background-background-danger-default;
  color: variable.$background-background-danger-bold-default;
}
.custom_toast_success {
  background-color: #38c793;
  color: white;
}
