@use '/src/styles/base/variables' as variable;

.progressbar-container {
  position: relative;

  .loading {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 0.5rem;
    background: variable.$background-background-neutral-default;
    // box-shadow: inset 0 0 5px rgba(variable.$black, 0.2);
    border-radius: 4px;
    overflow: hidden;
  }

  .loading:after {
    content: '';
    position: absolute;
    left: 0;
    width: var(--progress-bar-value);
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    background: variable.$background-background-success-bold-default;
  }
}
