@use '../_common-input-styles' as input;
@use '/src/styles/base/variables.scss' as variable;
@use '/src/styles/mixins/layout.scss' as layout;

.pincode-input-container {
  @include layout.flex($justify: center);
  width: 100%;
  gap: 0.5rem;
}

.pincode-input-text {
  text-align: center;
  font-size: 2.5rem;
  width: 100%;
  height: 4rem;
  border-radius: 0.25rem;
  @extend .input-field-container;

  input {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: variable.$text-text-primary;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
  }
}
