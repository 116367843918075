@use '/src/styles/base/variables.scss' as variable;
@use '/src/styles/base/typography.scss' as typography;
@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;

.dashboard_page {
  .header {
    @include layout.flex($align: center, $justify: space-between);
    flex-wrap: wrap;

    .filter {
      width: 147px;
    }
  }

  .api_call_chart_container {
    height: 100%;
    .api_call_chart {
      height: calc(100% - 3.25rem);
    }
  }

  .table_mobile_card,
  .recent_api_call {
    margin-top: 1.25rem;
  }

  .recent_api_call {
    .request-id-field {
      max-width: 12rem;
    }
  }
}
