.placeholder {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.75rem;

  .logo_display {
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 999px;
    object-fit: contain;
  }

  .button_row {
    display: flex;
    gap: 0.5rem;

    .button {
      padding: 0.5rem 0.75rem;
    }
  }
}
.modal_description {
  margin-bottom: 1rem;
  .title {
    margin-bottom: 0.25rem;
  }
}
