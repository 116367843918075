.NotFoundPage {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;

  .icon{
    width: 1rem;
  }
}
