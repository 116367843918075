@use '/src/styles/base/_variables.scss' as variable;
@use '/src/styles/mixins/_layout' as layout;
@use '/src/styles/mixins/_breakpoints' as breakpoint;
@use '/src/styles/base/typography' as typography;
@use '/src/pages/business/audit-trail/Api-logs/audit-details/audit-details' as
  audit-trail;

.app-details-page {
  @extend .view-role-page;

  .page-header {
    @include layout.flex($justify: space-between);

    .title-row {
      @include layout.flex($justify: center);
      gap: 0.75rem;
      width: 100%;
    }
    .logo-container {
      width: 3.75rem;
      height: 3.75rem;
      border-radius: variable.$card-radius;
      @include layout.flex($justify: center, $align: center);
    }

    .button-row {
      @include layout.flex($justify: flex-end, $align: center);
      gap: 0.25rem;
    }
    .delete-app-button {
      color: variable.$text-text-red;
    }
    .app-id {
      @include layout.flex();
      overflow-wrap: anywhere;
      gap: 0.25rem;
      color: variable.$text-text-tetiary;
    }
    .copy-app-id {
      width: 1rem;
      min-width: 20px;
      color: variable.$text-text-tetiary;
    }
  }

  .app_analytics_dashboard {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .details-card {
    .detail-row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .service-field {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
      }
      @include breakpoint.media('<tablet') {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }
    }
  }
}

.reset-app-modal {
  .token-action-buttons {
    display: flex;
    background: variable.$background-background-neutral-default;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    right: 0 !important;

    .click-to-copy-icon {
      width: 1.25rem;
      height: 1.25rem;
      color: variable.$background-background-neutral-bold-default;
      margin: 0 0.325rem;
      cursor: pointer;
    }
  }
}
