@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;
@use '/src/styles/base/typography' as typography;
@use '/src/styles/base/variables' as variable;
@use '../decision-model-manage' as decision-model-styles;

.modules-container {
  min-width: 16.5rem;

  @include breakpoint.media('<LGdesktop') {
    min-width: 15.4rem;
  }

  @include breakpoint.media('<desktop') {
    min-width: 100%;
  }

  .module-list-container {
    max-width: 100%;
    overflow-x: auto;
    padding-bottom: 0.3rem;
  }
  .module-list {
    @include layout.flex();
    flex-direction: row;
    gap: 0.89rem;
  }

  .module-card {
    cursor: pointer;
  }
}

.module-card {
  border: 0.056rem solid variable.$grey-700;
  background: variable.$grey-100;
  padding: 0.89rem 1.33rem;
  color: variable.$black;
  width: 100%;
  min-width: 10rem;
  height: 5.33rem;
  cursor: pointer;

  .card-title {
    @extend .xs;
    font-weight: bold;
    margin-bottom: 0.22rem;
    text-transform: capitalize;
  }

  .provider,
  .boolean-value {
    @extend .xxs;
    margin-top: 0.22rem;
  }

  .boolean-value {
    color: variable.$grey-600;

    & [data-boolean-value='true'] {
      color: variable.$success-500 !important;
    }

    & [data-boolean-value='false'] {
      color: variable.$error-500 !important;
    }
  }

  &.active {
    border: 0.056rem solid variable.$secondary-col-500;
    background-color: variable.$secondary-col-100;
  }
}
