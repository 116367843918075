@use "/src/styles/base/variables.scss" as variable;

.wizard-nav-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  gap: 0.25rem;
  margin: 0.5rem 0 0.375rem;
}

.step-nav-indicator {
  width: 100%;
  display: inline-block;
  content: "";
  background-color: variable.$secondary-col-100;
  height: 0.25rem;
  border-radius: variable.$rounded-full;

  &.active {
    background-color: variable.$secondary-col-500;
  }
}
