@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;
@use '/src/styles/base/typography' as typography;
@use '/src/styles/base/variables' as variable;

$decision-page-body-gap: 1.33rem;
$card-height: 5.056rem;

.decision-model-page {
  .page-header {
    @include layout.flex($justify: space-between);

    .button-row {
      @include layout.flex($justify: flex-end, $align: center);
      gap: 1rem;

      .icon {
        width: 0.89rem;
      }
    }
  }

  .page-title {
    display: inline-block;
    color: variable.$text-color;
  }

  .model-id {
    @extend .sm;
  }

  .heading-container {
    @include layout.flex($align: center, $justify: space-between);

    .goto-model {
      @include layout.flex($align: center, $justify: center);
      gap: 0.44rem;
      color: variable.$grey-500;
      @extend .caption-12;
      font-weight: bold;
      text-align: right;

      .icon {
        @include layout.flex($align: center, $justify: center);
      }
    }
  }

  .decision-model-body {
    @include layout.flex();
    flex-direction: column;
    gap: $decision-page-body-gap;

    .module-section {
      width: 100%;
    }

    .decision-model-body-grid {
      width: 100%;
      height: 80vh;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $decision-page-body-gap;

      @include breakpoint.media('<tablet') {
        grid-template-columns: none;
        grid-template-rows: repeat(2, 1fr);
        height: fit-content;
      }
    }
  }

  .decision-card-title {
    @extend .sm;
    font-weight: bold;
  }

  .decision-card-header {
    @include layout.flex($align: center, $justify: space-between);
    gap: 0.44rem;
    margin-bottom: 1.33rem;

    .edit-button {
      @include layout.flex($align: center);
      gap: 0.44rem;

      .icon {
        width: 0.8rem;
        @include layout.flex($align: center, $justify: center);
        color: variable.$info-500;
      }
    }
  }
}
