@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;
@use '/src/styles/base/typography' as typography;
@use '/src/styles/base/variables' as variable;

.change-logo-card {
  margin-top: 1rem;

  .file-size-notice {
    @extend .caption-12;
    color: variable.$black;
    margin-top: 0.22rem;
  }

  .file-upload-container {
    @include layout.flex($align: center, $justify: center);
  }

  .logo-display {
    max-width: 150px;
    max-height: 100px;
    object-fit: contain;
    margin: 0 20px;
  }

  .change-password {
    @include layout.flex($align: center);
    flex-direction: column;
    gap: 1rem;
  }
}

.profile-password-2fa-container,
.profile-management-card {
  margin-top: 1rem;
}

.profile-management-card {
  .detail-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.22rem;
    @include breakpoint.media('<tablet') {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .detail-field {
      display: flex;
      flex-direction: column;
      gap: 0.44rem;

      .field-title {
        @extend .xs;
        color: variable.$text-text-tetiary;
        text-transform: uppercase;
      }

      p {
        @extend .sm;
        color: variable.$text-text-primary;
      }
    }
  }
}
