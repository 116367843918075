@use '/src/styles/base/variables.scss' as variable;
@use '/src/styles/base/typography.scss' as typography;

.api-log-table {
  padding-left: 0;
  padding-right: 0;

  .request-id-field {
    max-width: 15rem;
  }
}
