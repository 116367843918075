.settings-page {
  .twofa-qrcode-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .twofa-qrcode {
    width: 10rem;
    height: 10rem;
    margin-top: 1.11rem;
  }
}
