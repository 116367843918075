@use '/src/styles/base/_variables.scss' as variable;
@use '/src/styles/mixins/_layout' as layout;
@use '/src/styles/mixins/_breakpoints' as breakpoint;
@use '/src/styles/base/typography' as typography;

.view-role-page {
  .page-title {
    display: inline-block;
    color: variable.$text-color;
  }

  .separator {
    margin: 0 0.22rem;
    color: variable.$info-400;
  }

  .view-role {
    @extend .sm;
  }

  .close-btn {
    color: variable.$black;
    background-color: transparent;
    border: 1px solid variable.$grey-400;
    padding: 0.67rem;
    width: 2.22rem;
    height: 2.22rem;
    margin-top: 0.89rem;
  }

  .details-card {
    &:not(:first-child) {
      margin-top: 1.25rem;
    }

    .detail-row {
      &:not(:first-child) {
        padding-top: 1.25rem;
      }
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;
      @include breakpoint.media('<tablet') {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }

      .detail-field {
        display: flex;
        flex-direction: column;
        gap: 0.44rem;

        .field-title {
          @extend .xs;
          font-weight: 400;
          text-transform: uppercase;
        }

        p {
          @extend .sm;
          color: variable.$text-text-primary;
          word-break: break-word;
        }
      }
    }
  }
}
