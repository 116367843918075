@use "/src/styles/mixins/layout" as layout;
@use "/src/styles/mixins/breakpoints" as breakpoint;
@use "/src/styles/base/typography" as typography;
@use "/src/styles/base/variables" as variable;
@use "../decision-model-manage" as decision-model-styles;
@use "../module/modules" as module-styles;

$main-config-section-padding: 0.889rem;

.configuration-container {
  background-color: variable.$grey-200;
  outline: 0.22rem solid variable.$white;
  padding: 1.33rem 0.89rem;
  height: 100%;

  .configuration-card {
    height: decision-model-styles.$card-height;
  }

  .module-version-info {
    @extend .module-card;
    background-color: variable.$white;
    border-color: variable.$grey-400;
    margin-bottom: 0.89rem;

    p {
      @extend .xxs;
      margin-top: 0.44rem;
    }

    .card-header {
      @include layout.flex($align: center, $justify: space-between);
      @extend .xxs;

      .module-name {
        color: variable.$primary-col-500;
        text-transform: capitalize;
      }

      .id {
        color: variable.$grey-500;
      }
    }
  }

  .main-configuration-card {
    @extend .module-card;
    background-color: variable.$white;
    border-color: variable.$grey-400;
    color: variable.$black;
    padding-left: 1rem;
    padding-right: 1rem;
    height: fit-content;
    max-height: fit-content;

    .decision-card-header {
      padding-bottom: $main-config-section-padding;
      margin-bottom: 0;
      border-bottom: 0.056rem solid variable.$grey-300;
    }

    .decision-card-title {
      @extend .xs;
      text-transform: capitalize;
    }

    .edit-button {
      @extend .tiny;
      color: variable.$grey-400;
    }

    .add-field-button {
      border: 0;
      @extend .tiny;

      .icon {
        color: variable.$info-500;
        width: 0.89rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .module-decision-form {
      padding: $main-config-section-padding 0;
      margin-bottom: 0;
      border-bottom: 0.056rem solid variable.$grey-300;
    }
  }

  .configuration-body {
    height: 18.722rem;
    overflow-y: auto;
    padding: $main-config-section-padding 0;
    position: relative;
    flex: 1;

    & .configuration-body-container {
      max-height: 100%;
    }

    & .configuration-field:not(:first-child) {
      border-top: 0.056rem solid variable.$grey-300;
      padding-top: $main-config-section-padding;
      margin-top: $main-config-section-padding;
    }

    .configuration-text-editor{
      height: 100%;
      width: 100%;
      resize: none;
      outline: 0;
      border: 0;
      font-family: inherit;
    }
  }

  .configuration-footer {
    @include layout.flex($align: center, $justify: flex-end);
    // margin-top: 1.667rem;
    padding-top: 1.167rem;
    border-top: 0.056rem solid variable.$grey-300;
  }
}
