@font-face {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-weight: 100 - 900;
  font-style: normal;
  font-variation-settings: 'slnt' 0;
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 100 - 900;
  font-style: normal;
  font-variation-settings: 'slnt' 0;
}
