/// Font Sizes ///
.hero {
  font-size: 4.44rem;
  line-height: 6rem;
}

h1 {
  font-size: 3.56rem;
  line-height: 4.78rem;
}

h2 {
  font-size: 2.67rem;
  line-height: 3.61rem;
}

h3 {
  font-size: 3rem;
  line-height: 3.25rem;
  font-family:
    'Montserrat',
    'Source Sans Pro',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue';
}

h4 {
  font-size: 1.78rem;
  line-height: 2.33rem;
}

h5 {
  font-size: 1.44rem;
  line-height: 1.94rem;
}

h6 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  font-family:
    'Montserrat',
    'Source Sans Pro',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue';
}

.lead {
  font-size: 1.125rem;
  line-height: 1.625rem;
}

p {
  font-size: 1rem;
  line-height: 1.33rem;
}

.sm,
label {
  font-size: 0.875rem;
  line-height: 1.22rem;
}

.xs {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.xxs {
  font-size: 0.675rem;
  line-height: 0.89rem;
}

.tiny {
  font-size: 0.56rem;
  line-height: 0.78rem;
}

.caption-12 {
  @extend .xxs;
  letter-spacing: 0.056rem;
}

.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}
.font-Montserrat {
  font-family:
    'Montserrat',
    'Source Sans Pro',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue';
}
