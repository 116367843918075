@use '/src/styles/base/variables' as variable;
@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;
@use '/src/styles/base/typography' as typography;

.report_container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  position: relative;
}

.report_card {
  box-sizing: border-box;
  flex: 0 0 calc(33.33% - 16px);
  background: #fff;
  border-radius: 0.75rem;
  padding: 16px;
}

@media (max-width: 900px) {
  .report_card {
    flex: 0 0 calc(50% - 16px);
  }
}

@media (max-width: 600px) {
  .report_card {
    flex: 0 0 100%;
  }
}

.report_card_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.report_card_info {
  margin-bottom: 20px;
}

.report_card_title {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.report_card_description {
  font-size: 0.9rem;
}

.report_card_button {
  margin-top: auto;
  justify-content: end;
}

.page_loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  padding: 20px;
}
