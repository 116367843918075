.mfa-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .mfa-modal-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
  }

  .sub-heading {
    font-size: 0.875rem;
    display: flex;
  }

  .mfa-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0.44rem;

    button {
      margin-top: 0;
      width: 100%;
    }

    .mfa-close-button {
      width: 25%;
    }
  }
}
