@use '/src/styles/base/variables.scss' as variable;
@use '/src/styles/mixins/layout.scss' as layout;
@use '/src/styles/mixins/breakpoints.scss' as breakpoint;
@import '../login/_login.scss';

.complete-setup-page {
  @extend .login-page;

  form {
    .button-row {
      @include layout.flex($justify: center, $align: center);
      column-gap: 0.44rem;
      button {
        margin-top: 1.78rem;
        margin-bottom: 0;
      }
    }
  }

  .city-state-row {
    @include layout.flex($justify: space-between, $align: center);
    column-gap: 0.89rem;
    .input-container {
      width: 100%;
    }

    @include breakpoint.media('<tablet') {
      flex-direction: column;
    }
  }

  .upload-icon {
    color: variable.$secondary-col-500;
    width: 0.89rem;
    display: inline-block;
  }
}
