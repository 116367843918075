@use '/src/styles/base/variables' as variable;
@use '/src/styles/base/_typography' as typography;
@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;

.tab-list-container {
  @extend .sm;
  overflow-x: auto;
  max-width: 100%;
  padding-bottom: 0;
  padding-top: 0;
  border-bottom: 1px solid variable.$border-border-disabled;

  .react-tabs__tab-list {
    list-style-type: none;
    @include layout.flex($align: center);
    min-width: max-content;

    .react-tabs__tab {
      padding: 0.5rem 0.75rem;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: variable.$text-text-button-brand-tiffanyblue;
      }
    }

    .react-tabs__tab--selected {
      position: relative;
      outline: 0;
      color: variable.$text-text-button-brand-tiffanyblue;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        width: 100%;
        height: 2px;
        background-color: variable.$border-border-brand-default;
        // border-radius: variable.$rounded-full;
      }
    }
  }

  /* Set the width and height of the scrollbar */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.tab-content {
  margin-top: 1rem;
}
