@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;
@use '/src/styles/base/typography' as typography;
@use '/src/styles/base/variables' as variable;
@use '../module/modules' as module-styles;
@use '../_decision-model-manage' as decision-model-styles;

.workspaces-container {
  background-color: variable.$grey-100;
  outline: 0.22rem solid variable.$white;
  height: 100%;
  padding-bottom: 0;

  .workspace-list {
    @include layout.flex();
    flex-direction: column;
    gap: 0.89rem;
    overflow-y: auto;
    max-height: 35rem;

    .workspace-list-item {
      display: grid;
      grid-template-columns: 30px 1fr;
      gap: 1.5rem;
      width: 100%;

      .item-idex-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: calc(1.5rem + (decision-model-styles.$card-height / 8));
      }

      .item-index {
        @extend .sm;
        font-weight: bold;
        color: variable.$grey-300;
      }

      .workspace-card-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 1.5rem;
      }
    }
  }

  .decision-card-header {
    .edit-button {
      @extend .tiny;
    }
  }
  .decision-card-title {
    @include layout.flex($align: center);
    margin-bottom: 0;
    gap: 0.44rem;
  }

  .basic-model-info {
    @include layout.flex($align: flex-start, $justify: space-between);
    margin-bottom: 1.33rem;
    gap: 0.33rem;

    .basic-model {
      width: 100%;
    }

    .title {
      @extend .caption-12;
      font-weight: bold;
      color: variable.$grey-400;
    }

    p {
      @extend .xs;
      color: variable.$black;
    }

    @include breakpoint.media('<tablet') {
      flex-wrap: wrap;
      gap: 0.5rem;
    }
  }

  .workspace-card {
    min-height: decision-model-styles.$card-height;
    height: fit-content;
    box-shadow: variable.$shadow-60;
    border: 0;
    background-color: variable.$white;
    padding: 0.89rem;
    @extend .module-card;

    p {
      @extend .tiny;
    }
  }
}
