@use '/src/styles/base/_variables.scss' as variable;
@use '/src/styles/mixins/_layout' as layout;
@use '/src/styles/mixins/_breakpoints' as breakpoint;

.slidepane-container {
  .slidepane-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: variable.$modal-background;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: transform 0.3s ease-in-out;
  }

  .slidepane {
    position: fixed;
    top: 0;
    right: 0;
    width: 35%;
    max-width: 448px;
    height: 100%;
    background: variable.$white;
    z-index: variable.$sidepane-z-index;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    overflow-y: auto;

    .slidepane-header {
      padding: 1rem 1.5rem;
      border-bottom: 1px solid variable.$grey-100;
      position: sticky;
      z-index: variable.$sidepane-header-z-index;
      top: 0;
      background: variable.$white;
      @include layout.flex($align: center, $justify: space-between);

      .close-btn {
        color: variable.$black;
        background-color: transparent;
      }

      .title {
        font-size: variable.$modal-header-text-size;
        width: 100%;
        width: -moz-available;
        width: -webkit-fill-available;
        width: stretch;
      }
    }

    .slidepane-body {
      padding: 1rem 1.5rem;
      overflow-y: auto;
      min-height: calc(100vh - 60px - 75px);
    }

    .slidepane-footer {
      padding: 1rem 1.5rem;
      border-top: 1px solid variable.$grey-100;
      position: sticky;
      bottom: 0;
      width: 100%;
      background: variable.$white;
      z-index: variable.$sidepane-header-z-index;
    }
  }

  @include breakpoint.media('<tablet') {
    .slidepane {
      width: 90%;
    }
  }

  &.open {
    .slidepane-backdrop {
      visibility: visible;
      opacity: 1;
      z-index: variable.$sidepane-z-index;
    }
    .slidepane {
      transform: translateX(0);
    }
  }
}
