@use '/src/styles/base/_variables' as variable;
@use '/src/styles/mixins/_layout' as layout;
@use '/src/styles/base/_typography' as typography;
@use '/src/styles/mixins/_breakpoints' as breakpoint;

.metrics_card {
  @extend .sm;
  min-height: 12rem;

  .metrics_card_body {
    text-align: center;
    height: calc(100% - 3.25rem);
    width: 100%;

    .value {
      font-size: 2.5rem;
      line-height: 3rem;

      span {
        font-size: 1.5rem;
      }
    }
  }

  .footer {
    margin-top: 0.5rem;
  }
}
