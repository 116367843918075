@use '/src/styles/base/variables.scss' as variable;
@use '/src/styles/mixins/layout.scss' as layout;
@use '/src/styles/base/typography' as typography;

// .react-tel-input .selected-flag .flag {
//   display: none;
// }

.phoneContainer {
  .flag-dropdown {
    border-color: transparent !important;
  }

  .selected-flag {
    overflow: hidden;
    border-color: transparent !important;
  }
}

.phoneInput {
  width: 100% !important;
  padding-left: 50px !important;
  border-color: transparent !important;
}
