@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;
@use '/src/styles/base/typography' as typography;
@use '/src/styles/base/variables' as variable;
@use '../wallet/_fund-wallet.scss' as fund-wallet-style;

.create-app-form {
  .form-section {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .field-row {
    @include layout.flex($justify: space-between);
    column-gap: 0.67rem;
    width: 100%;

    .input-container {
      width: 100%;
    }

    @include breakpoint.media('<desktop') {
      flex-direction: column;
    }
  }
}
