@use './variables.scss' as variable;
@use '../mixins/breakpoints.scss' as breakpoint;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;

  @include breakpoint.media('>tablet') {
    font-size: 14px;
  }

  @include breakpoint.media('>LGdesktop') {
    font-size: 16px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-family:
    'Inter',
    'Source Sans Pro',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  color: variable.$text-color;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

.text-text-primary {
  color: variable.$text-text-primary;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  font-family: inherit;
}

button,
[role='button'] {
  cursor: pointer;
}

#file-upload-button {
  display: none;
}

img {
  width: 100%;
  max-width: 100%;
}

/* Custom Scroll Bar */
::-webkit-scrollbar {
  width: 5px;
  max-height: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: variable.$grey-300;
  border-radius: 10px;
  margin-right: 5px;
}

//prevent select
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type='number'] {
  -moz-appearance: textfield !important;
}

::-webkit-datetime-edit {
  font-family:
    'Inter',
    'Source Sans Pro',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    sans-serif;
}
input[type='date'] {
  background: transparent;
}
