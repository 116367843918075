@use '/src/styles/base/variables' as variable;
@use '/src/styles/base/typography.scss' as typography;
@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;

$sidebar-active-bg: #40baba14;
$sidebar-hover-bg: variable.$background-background-neutral-bold-pressed;

.sidebar {
  width: variable.$sidebar-width;
  background: variable.$background-background-neutral-bold-pressed;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem 0 0;
  color: variable.$white;

  @include breakpoint.media('<tablet') {
    width: variable.$sidebar-width-mobile;
    padding: 2rem 0 0;
  }

  .logo {
    margin: 0 variable.$sidebar-padding;
    position: relative;
    @include layout.flex($align: center, $justify: space-between);

    @include breakpoint.media('<tablet') {
      padding-left: variable.$sidebar-padding-mobile;
      margin: 0 variable.$sidebar-padding-mobile;
    }

    img {
      width: 7.6375rem;
    }
    .small-logo {
      display: none;
      width: 1.33rem;
    }

    .sidebar-toggle-btn {
      background: transparent;
      padding: 0;

      .icon {
        width: 1.5rem;
        @include layout.flex($align: center, $justify: center);
        transition: all 0.2s ease-in-out;
      }
    }
  }

  nav {
    padding: 2rem 0;
    height: 85vh;
    overflow-y: auto;
  }

  .nav-link {
    @include layout.flex($align: center);
    width: 100%;
    gap: 0.75rem;
    padding: 0.875rem variable.$sidebar-padding;
    cursor: pointer;
    position: relative;
    color: variable.$white;
    @extend .sm;
    text-transform: capitalize;
    transition: background-color 0.2s ease-in-out;

    @include breakpoint.media('<tablet') {
      padding: 0.875rem variable.$sidebar-padding-mobile;
    }

    &[data-subnav='true'] {
      display: grid;
      grid-template-columns: 0.15fr 0.6fr 0.25fr;
      gap: 0;
    }

    .nav-title {
      text-align: left;
      @include breakpoint.media('<tablet') {
        font-size: 0.875rem;
      }
    }

    .icon {
      width: 1.25rem;
      @include layout.flex($align: center);
    }

    .dropdown.icon {
      width: 0.875rem;
      height: 100%;
      @include layout.flex($align: center, $justify: center);
      transition: all 0.3s ease-in-out;
    }

    &.active {
      position: relative;
      background-color: $sidebar-active-bg;
      box-shadow: 3px 0px 0px 0px
        variable.$background-background-tifannyblue-bold-default inset;

      .icon {
        color: variable.$background-background-tifannyblue-bold-default;
      }
    }
  }

  .subnav-container {
    padding-left: calc(variable.$sidebar-padding / 1.5);
    border-left: 0.056rem solid variable.$primary-col-200;
    transition: 0.3s all ease-in-out;
    display: none;
    margin: 0.67rem 0 0.67rem calc(variable.$sidebar-padding / 0.75);
  }

  .subnav-link {
    @extend .nav-link;
    padding: 0.72rem variable.$sidebar-padding;
    @include breakpoint.media('<tablet') {
      padding: 0.72rem variable.$sidebar-padding-mobile;
    }
  }

  .api-doc-button {
    // padding: 0 variable.$sidebar-padding;
    position: absolute;
    bottom: 0;
    background: variable.$primary-col-700;
    left: 0;
    right: 0;

    @include breakpoint.media('<tablet') {
      padding: 0 variable.$sidebar-padding-mobile;
    }
  }

  [class*='nav-link expanded'] {
    & .dropdown {
      transform: rotate(90deg);
      transform-origin: center;
    }

    & ~ .subnav-container {
      display: block;
    }
  }
}

[class='sidebar collapsed'] {
  width: variable.$sidebar-collapsed-width;

  .logo {
    .large-logo,
    .small-logo {
      display: none;
    }

    .sidebar-toggle-btn .icon {
      transform: rotate(180deg);
    }
  }

  .nav-link {
    position: static;
    @include layout.flex($align: center, $justify: center);

    &.active {
      position: static;
    }

    .nav-title {
      display: none;
    }

    .icon {
      width: 1.33rem;
    }

    .dropdown.icon {
      display: none;
    }

    .collapse-nav-link-title {
      display: none;
      position: absolute;
      left: 80%;
      z-index: 10000000;
      background:  $sidebar-hover-bg;
      padding: 1rem;
      border-radius: variable.$card-radius;
      min-width: fit-content;
      text-wrap: nowrap;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent $sidebar-active-bg transparent transparent;
      }
    }

    &:hover {
      .collapse-nav-link-title {
        display: block;
      }
    }
  }

  .subnav-container {
    display: none !important;
  }
}
