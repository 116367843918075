@use '/src/styles/mixins/layout.scss' as layout;
@use '/src/styles/base/variables' as variable;

.filled-btn {
  @include layout.flex($align: center, $justify: center);
  gap: 0.25rem;
  padding: 0.75rem 1rem;
  border-radius: variable.$button-radius;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.875rem;

  .button-spinner,
  .button-icon {
    width: 1rem;
    height: 1rem;
    margin: 0;
  }

  &[data-variant='outlined'] {
    background-color: transparent;
    border: 1px solid variable.$border-border-default;
    color: variable.$text-text-primary;

    &:disabled {
      color: variable.$background-background-tifannyblue-bold-disabled;
      cursor: not-allowed;
    }

    &:not(:disabled):hover {
      border-color: variable.$background-background-neutral-hovered;
    }

    &:disabled {
      color: variable.$text-text-disabled;
    }

    &:active {
      background-color: variable.$border-border-hover;
    }

    &:focus {
      box-shadow: 0px 0px 0px 4px #f1f2f4;
      box-shadow: 0px 0px 0px 2px #ffffff;
    }
  }

  &[data-variant='underlined'] {
    display: inline-flex;
    width: fit-content;
    background-color: transparent;
    border-bottom: 2px solid
      variable.$background-background-tifannyblue-bold-default;
    color: variable.$background-background-tifannyblue-bold-default;
    font-size: inherit;
    padding: 0.44rem 0 0;
    border-radius: 0;

    &:disabled {
      color: variable.$background-background-tifannyblue-bold-disabled;
      cursor: not-allowed;
    }
  }

  &[data-variant='ghost'] {
    display: inline-flex;
    width: fit-content;
    background-color: transparent;
    color: variable.$background-background-tifannyblue-bold-default;
    padding: 0;
    border-radius: 0;

    &:hover {
      background-color: transparent;
      color: variable.$background-background-tifannyblue-bold-hovered;
    }

    &:focus {
      background-color: transparent;
      color: variable.$background-background-tifannyblue-bold-pressed;
      box-shadow: none;
    }

    &:disabled {
      color: variable.$background-background-tifannyblue-bold-disabled;
      cursor: not-allowed;
    }
  }
}

.icon-button-container {
  padding: 0.5rem;
}

.primary-btn-color {
  background: variable.$background-background-tifannyblue-bold-default;
  color: white;

  &:hover {
    background-color: variable.$background-background-tifannyblue-bold-hovered;
  }

  &:active {
    background-color: variable.$background-background-tifannyblue-bold-pressed;
  }

  &:focus {
    background-color: variable.$background-background-tifannyblue-bold-default;
    box-shadow: 0 0 0 0.2rem #35b4b480;
  }

  &:disabled {
    background: variable.$background-background-tifannyblue-bold-disabled;
    color: variable.$text-text-disabled;
  }

  &[data-variant='outlined'] {
    border-color: variable.$border-border-default;
    color: variable.$text-text-primary;

    &:disabled {
      color: variable.$background-background-tifannyblue-bold-disabled;
      cursor: not-allowed;
    }

    &:not(:disabled):hover {
      border-color: variable.$background-background-neutral-hovered;
    }

    &:disabled {
      color: variable.$text-text-disabled;
    }

    &:active {
      background-color: variable.$border-border-hover;
    }

    &:focus {
      box-shadow: 0px 0px 0px 4px #f1f2f4;
      box-shadow: 0px 0px 0px 2px #ffffff;
    }
  }

  &[data-variant='underlined'] {
    background-color: transparent;
    border-color: variable.$background-background-tifannyblue-bold-default;
    color: variable.$background-background-tifannyblue-bold-default;
    &:disabled {
      color: variable.$background-background-tifannyblue-bold-disabled;
    }
  }

  &[data-variant='ghost'] {
    background-color: transparent;
    color: variable.$background-background-tifannyblue-bold-default;

    &:hover {
      color: variable.$background-background-tifannyblue-bold-hovered;
    }

    &:focus {
      color: variable.$background-background-tifannyblue-bold-pressed;
    }

    &:disabled {
      color: variable.$background-background-tifannyblue-bold-disabled;
    }
  }
}

.secondary-btn-color {
  background-color: variable.$info-500;
  color: white;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: variable.$info-600;
  }

  &:disabled {
    background-color: variable.$grey-400;
    cursor: not-allowed;
  }

  &[data-variant='outlined'] {
    background-color: transparent;
    outline: 1px solid variable.$info-500;
    color: variable.$info-500;

    &:hover {
      color: variable.$white;
      background-color: variable.$info-600;
    }
  }

  &[data-variant='underlined'] {
    background-color: transparent;
    border-bottom: 2px solid variable.$info-500;
    color: variable.$info-500;
    font-size: inherit;
    padding: 0.44rem 0 0;
    border-radius: 0;

    &:disabled {
      color: variable.$grey-400;
      cursor: not-allowed;
    }
  }
}

.danger-btn-color {
  background-color: variable.$background-background-danger-bold-default;
  color: white;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: variable.$background-background-danger-bold-hovered;
  }

  &:focus {
    background-color: variable.$background-background-danger-bold-default;
    box-shadow: 0 0 0 0.2rem #c9372c80;
  }

  &:disabled {
    background-color: variable.$grey-400;
    cursor: not-allowed;
  }

  &[data-variant='outlined'] {
    background-color: transparent;
    outline: 1px solid variable.$error-500;
    color: variable.$error-500;

    &:hover {
      color: variable.$white;
      background-color: variable.$error-600;
    }
  }

  &[data-variant='underlined'] {
    background-color: transparent;
    border-bottom: 2px solid variable.$error-500;
    color: variable.$error-500;
    font-size: inherit;
    padding: 0.44rem 0 0;
    border-radius: 0;

    &:disabled {
      color: variable.$grey-400;
      cursor: not-allowed;
    }
  }
}

.purple-btn-color {
  background-color: variable.$purple-col;
  color: white;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: variable.$purple-col;
  }

  &:disabled {
    background-color: variable.$grey-400;
    cursor: not-allowed;
  }

  &[data-variant='outlined'] {
    background-color: transparent;
    outline: 1px solid variable.$purple-col;
    color: variable.$purple-col;

    &:hover {
      color: variable.$white;
      background-color: variable.$purple-col;
    }
  }

  &[data-variant='underlined'] {
    background-color: transparent;
    border-bottom: 2px solid variable.$purple-col;
    color: variable.$purple-col;
    font-size: inherit;
    padding: 0.44rem 0 0;
    border-radius: 0;

    &:disabled {
      color: variable.$grey-400;
      cursor: not-allowed;
    }
  }
}

.neutral-btn-color {
  background: variable.$background-background-neutral-default;

  &:hover {
    background-color: variable.$background-background-neutral-hovered;
  }

  &:active {
    background-color: variable.$background-background-neutral-bold-pressed;
  }

  &:focus {
    background-color: variable.$background-background-neutral-default;
    box-shadow: 0 0 0 0.2rem #35b4b400;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &[data-variant='outlined'] {
    border-color: variable.$border-border-default;
    color: variable.$text-text-primary;

    &:disabled {
      color: variable.$background-background-tifannyblue-bold-disabled;
      cursor: not-allowed;
    }

    &:not(:disabled):hover {
      border-color: variable.$background-background-neutral-hovered;
    }

    &:disabled {
      color: variable.$text-text-disabled;
    }

    &:active {
      background-color: variable.$border-border-hover;
    }

    &:focus {
      box-shadow: 0px 0px 0px 4px #f1f2f4;
      box-shadow: 0px 0px 0px 2px #ffffff;
    }
  }
}
