@use "/src/styles/mixins/layout" as layout;
@use "/src/styles/mixins/breakpoints" as breakpoint;
@use "/src/styles/base/typography" as typography;
@use "/src/styles/base/variables" as variable;

.table-content {
  margin-top: 1.0567rem;
}

.api-pricing-tab {
  @include breakpoint.media("<desktop") {
    .table-container table {
      min-width: 800px;
    }
  }
}

.endpoint-pricing-table {
  .endpoint-field,
  .price-field {
    @extend .sm;
  }

  .endpoint-field {
    color: variable.$black !important;
  }

  .price-field {
    color: variable.$grey-950 !important;
  }

  [data-service="cost"]{
    background-color: variable.$error-100;
    color: variable.$error-500;
  }

  [data-service="charge"]{
    background-color: variable.$success-100;
    color: variable.$success-500;
  }

  .url-field {
    @include layout.flex($align: center);
    gap: 0.22rem;
    color: variable.$grey-700;
    @extend .xs;

    .copy-button {
      width: 1.33rem;
      display: inline-block;
    }
    .copy-icon {
      color: variable.$primary-col-500;
    }
  }
}
