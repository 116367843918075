@use '/src/styles/base/variables.scss' as variable;
@use '/src/styles/mixins/layout' as layout;
@use '../input-fields/common-input-styles' as common-input;

.drag-and-drop-container {
  position: relative;

  input {
    position: relative;
    height: 12.75rem;
    opacity: 0;
    width: 100%;
    cursor: pointer;
  }

  .mask {
    @include layout.flex($align: center, $justify: center);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
    max-height: 12.75rem;

    padding: 2.5rem;
    color: common-input.$placeholder-color;

    border-radius: 0.25rem;

    .add-image-label {
      @include layout.flex($align: center, $justify: center);
      flex-direction: column;
      margin-top: 0.75rem;

      .add-image-label-btn {
        padding: 0.5rem 0.75rem;
      }
    }

    .uploaded-image {
      @include layout.flex($align: center, $justify: center);
      background: transparent;
      flex-direction: column;
      position: relative;

      img {
        width: 10.875rem;
        height: 7rem;
        object-fit: cover;
        margin: 0 auto;
      }

      object {
        width: 14.875rem;
        height: 7rem;
        object-fit: cover;
        margin: 0 auto;
      }

      .remove-image {
        position: absolute;
        background: variable.$background-background-neutral-bold-default;
        color: #ffffff;
        width: 2rem;
        height: 2rem;
        padding: 0.5rem;
        border-radius: variable.$rounded-full;
        top: 0;
        right: 0;
        transform: translateX(50%);
        cursor: pointer;
      }
    }

    &.image-value-present {
      background-color: variable.$background-background-input-default;
    }
    &.image-value-absent {
      background-color: variable.$background-background-neutral-default;
      pointer-events: none;
    }
  }

  &:focus-within input:after {
    background-color: variable.$white;
  }

  .file-uploading {
    @include layout.flex($align: center);
    flex-direction: column;
    margin: 0 auto;

    .uploading-icon {
      @include layout.flex($align: center, $justify: center);
      width: 1.5rem;
      color: variable.$primary-col-500;
    }

    .progress-level {
      @extend .sm;
      color: variable.$black;
      margin-top: 0.56rem;
    }

    .progress-indicator {
      width: 100%;
      margin-top: 0.33rem;
      max-width: 90%;
    }

    .file-name {
      @extend .xxs;
      color: variable.$primary-col-500;
      margin-top: 0.22rem;
    }
  }

  .success-mask {
    background-color: variable.$success-100;

    .success-icon {
      @include layout.flex($align: center, $justify: center);
      width: 2.5rem;
      color: variable.$success-500;
    }
  }

  .error-mask {
    background-color: variable.$error-100;

    .error-icon {
      @include layout.flex($align: center, $justify: center);
      width: 2.5rem;
      color: variable.$error-500;
    }
  }
}
