@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;
@use '/src/styles/base/typography' as typography;
@use '/src/styles/base/variables' as variable;

.multi_select_checkbox {
  .loading_icon {
    margin: 1rem auto;
  }

  .search_input {
    margin-top: 1rem;
  }

  .selected_category_header {
    @include layout.flex($justify: space-between, $align: center);
    cursor: pointer;
    .selected_category_header_close_btn {
      background: transparent;
      padding: 0.25rem;
    }
  }

  .selected_categories {
    @include layout.flex();
    flex-direction: column;
    gap: 0.67rem;
    width: 100%;
    margin-top: 1rem;

    .selected_category {
      width: 100%;
    }
  }

  .selected_options {
    @include layout.flex();
    flex-direction: column;
    gap: 0.67rem;
    width: 100%;
    padding: 1rem 0;
  }

  .select_all_button {
    font-size: 0.875rem;
    align-self: flex-end;
  }
}
