@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/base/variables' as variable;

.toggle-component-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .switch {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 1rem;
  }

  .label {
    font-weight: 500;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: variable.$rounded-full;
    background-color: variable.$icon-icon-disabled;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 0.75rem;
    width: 0.75rem;
    left: 0.125rem;
    bottom: 0.125rem;
    background-color: variable.$white;
    border-radius: variable.$rounded-full;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  input:checked + .slider {
    background-color: variable.$background-background-tifannyblue-bold-default;
  }

  input:focus + .slider {
    box-shadow: 0 0 0 variable.$background-background-tifannyblue-bold-default;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(0.9rem);
    -ms-transform: translateX(0.9rem);
    transform: translateX(0.9rem);
  }
}
