.select-setting-section {
  margin-top: 1.5rem;
  width: max-content;

  .select-setting-option {
    margin-top: 0.78rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.8rem;

    .setting-title {
      text-transform: capitalize;
    }
  }
}
