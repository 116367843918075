@use '/src/styles/base/variables.scss' as variable;

.avatar-container {
  background: variable.$background-background-tifannyblue-default;
  color: variable.$white;
  padding: 0.25rem;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: variable.$rounded-full;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .avatar-image {
    border-radius: variable.$rounded-full;
    width: 2rem;
    height: 2rem;
    object-fit: fill;
  }
}
