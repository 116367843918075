@use '/src/styles/base/variables' as variable;
@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;
@use '/src/styles/base/typography' as typography;

.home_page_container {
  .home_page {
    @include layout.flex($justify: space-between, $align: flex-start);
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  }

  .title {
    color: variable.$text-text-primary;
    margin-bottom: 1.25rem;
  }

  .shortcut_card_row {
    @include layout.flex($justify: center, $align: center);
    width: 100%;
    gap: 1.25rem;

    .shortcut_card {
      @include layout.flex($justify: space-between, $align: flex-start);
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 1.5rem;
      min-height: 216px;

      .shortcut_card_icon {
        width: 2.5rem;
        height: 2.5rem;
        color: variable.$icon-icon-button-brand;
        background-color: variable.$background-background-tifannyblue-default;
        padding: 0.5rem;
        border-radius: variable.$rounded-full;
        @include layout.flex($justify: center, $align: center);
      }

      .shortcut_card_info {
        margin: 1.25rem 0;
      }

      .shortcut_card_title {
        color: variable.$text-text-primary;
        margin-bottom: 0.5rem;
      }
    }
  }

  @include breakpoint.media('<tablet') {
    .shortcut_card_row {
      flex-direction: column;
    }
  }
}

.getting_started_container {
  width: 100%;
}
