@use '/src/styles/base/variables.scss' as variable;
@use '/src/styles/mixins/layout.scss' as layout;
@use '/src/styles/mixins/breakpoints.scss' as breakpoint;

.topbar {
  @include layout.flex($align: center, $justify: space-between);
  width: 100%;
  height: variable.$topbar-height;
  padding: 1.125rem 0;
  background-color: #fff;
  border-bottom: 1px solid variable.$border-border-default;

  .logo-container {
    width: fit-content;
    padding: 0 variable.$sidebar-padding;
    @include layout.flex($align: center);
    display: none;
    @include breakpoint.media('<desktop') {
      display: flex;
    }

    .logo {
      width: 9.05rem;
      @include layout.flex($align: center);
    }

    .icon {
      width: 2rem;
    }
  }

  .topbar-content {
    @include layout.flex($align: center, $justify: space-between);
    padding: 0 variable.$body-horizontal-padding-desktop;
    width: 100%;
    .icon {
      cursor: pointer;
      @include layout.flex($justify: center, $align: center);
    }

    .shortcuts {
      @include layout.flex($align: center);
      gap: 1.5rem;

      .icon.search {
        display: none;
      }

      .icon.notification {
        width: 1.25rem;
      }

      .shortcut-group {
        @include layout.flex($align: center, $justify: center);
        gap: 0.5rem;

        .topbar-shortcut-button {
          color: variable.$icon-icon-subtle;
        }

        .app-state-toggle {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;

          .test {
            color: #ae2e24;
          }
        }
      }

      .test-mode-toggle {
        color: variable.$text-text-red;
      }

      .user-data {
        @include layout.flex($align: center);
        gap: 0.625rem;
        font-weight: 400;
        font-size: 0.875rem;
        cursor: pointer;
        color: variable.$text-text-primary;

        .icon {
          width: 00.89rem;
        }
      }
    }

    @include breakpoint.media('<desktop') {
      @include layout.flex($align: center, $justify: flex-end);

      .search {
        display: none;
      }

      .shortcuts .icon.search {
        display: block;
      }
    }
  }

  .logo-container,
  .topbar-content {
    @include breakpoint.media('<desktop') {
      padding: 0 variable.$body-padding-tablet;
    }
    @include breakpoint.media('<tablet') {
      padding: 0 variable.$body-padding-mobile;
    }
  }
}
