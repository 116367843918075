@use '/src/styles/base/_variables.scss' as variable;
@use '/src/styles/base/_typography.scss' as typography;
@use '/src/styles/mixins/_layout' as layout;
@use '/src/styles/mixins/breakpoints.scss' as breakpoint;

.tooltip {
  width: fit-content;
  max-width: 100%;
  position: relative;
  display: inline-block;
  cursor: pointer;

  .tooltip-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tooltip-content {
    visibility: hidden;
    opacity: 0;
    color: variable.$white;
    background-color: variable.$background-background-neutral-bold-pressed;
    padding: 0.25rem 0.75rem;
    text-align: center;
    min-width: fit-content;
    width: max-content;
    max-width: 386px;
    font-weight: 400;
    height: fit-content;
    border-radius: variable.$card-radius;
    position: absolute;
    transition: opacity 0.3s ease-in-out;
    @extend .sm;

    @include breakpoint.media('<tablet') {
      max-width: 260px;
    }

    &::after {
      content: '';
      position: absolute;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: variable.$background-background-neutral-bold-pressed
        transparent transparent transparent;
    }

    &.tooltip-content-top {
      bottom: 0.75rem;
      left: 50%;
      transform: translate(-50%, -0.75rem);
    }

    &.tooltip-content-top::after {
      top: 100%;
      left: 50%;
    }

    &.tooltip-content-bottom {
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0%);
    }

    &.tooltip-content-bottom::after {
      top: 0%;
      left: 50%;
      transform: rotate(180deg);
    }
  }

  &:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
  }
}
