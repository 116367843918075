@use '/src/styles/base/_variables.scss' as variable;
@use '/src/styles/mixins/_layout' as layout;
@use '/src/styles/mixins/_breakpoints' as breakpoint;

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  z-index: -1;

  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: variable.$modal-background;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: transform 0.3s ease-in-out;
  }

  .modal {
    position: absolute;
    top: 0%;
    left: 50%;
    min-height: 100px;
    background: variable.$white;
    padding: 0;
    z-index: variable.$sidepane-z-index;
    transform: translate(-50%, -50%) scale(0.3);
    opacity: 0;
    @include layout.flex($align: start, $justify: space-between);
    flex-direction: column;
    box-shadow: 0px 8px 12px 0px #091e4226;
    box-shadow: 0px 0px 1px 0px #091e424f;
    max-width: 95%;
    &.size-medium {
      width: 28rem;
    }
    &.size-large {
      width: 32rem;
    }

    &.animate {
      transition:
        transform 0.3s ease-in-out,
        opacity 0.5s ease-in-out;
    }

    .modal-header {
      width: 100%;
      padding: 1rem 1rem 0.875rem;
      border-bottom: 0.022rem solid variable.$grey-400;
      @include layout.flex($align: center, $justify: space-between);

      .title {
        font-weight: 700;
        color: variable.$text-text-primary;
        font-size: variable.$modal-header-text-size;
      }
    }

    .modal-body {
      padding: 1rem;
      height: 100%;
      width: 100%;
      overflow-y: auto;
    }

    .modal-footer {
      width: 100%;
      padding: 1rem;
      border-top: 0.022rem solid variable.$grey-400;
      @include layout.flex($align: center, $justify: space-between);
    }
  }

  &.open {
    z-index: variable.$sidepane-z-index;
    .modal-backdrop {
      visibility: visible;
      opacity: 1;
    }

    .modal-top-center {
      top: 0%;
      left: 50%;
      transform: translate(-50%, -0%);
    }
    .modal-center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }

  @include breakpoint.media('<desktop') {
    .modal {
      width: 60%;
      // padding: 1.78rem 2rem;
    }
  }

  @include breakpoint.media('<tablet') {
    .modal {
      width: 90%;
      // padding: 1.78rem 1rem;
    }
  }
}

.modal-footer-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 0.44rem;

  button {
    margin-top: 0;
    width: 100%;
  }

  .close-button {
    width: 25%;
  }

  @include breakpoint.media('<tablet') {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .close-button {
      width: 100%;
    }
  }
}
