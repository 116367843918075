@use '/src/styles/base/variables' as variable;
@use '/src/styles/base/_typography' as typography;
@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;

.success_modal {
  @include layout.flex($justify: center, $align: center);
  flex-direction: column;
  .icon {
    width: 5.375rem;
    height: 5.375rem;
    margin-bottom: 1rem;
  }
}
