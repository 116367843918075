@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;
@use '/src/styles/base/typography' as typography;
@use '/src/styles/base/variables' as variable;

.account-management-card,
.company-details-card {
  margin-top: 2.22rem;
}

.document-type-table {
  .icon {
    width: 1rem;
    height: 1rem;
    color: variable.$icon-icon-subtle;
  }
}

.card-heading-container {
  @include layout.flex($align: center);
  column-gap: 0.22rem;
  flex-wrap: wrap;

  .card-title {
    margin-bottom: 0;
  }
}

.change-logo-card {
  margin-top: 1rem;

  .file-upload-container {
    @include layout.flex($align: center, $justify: center);
    @include breakpoint.media('<desktop') {
      flex-wrap: wrap;
    }

    .logo-section {
      @include layout.flex($align: center, $justify: center);
      padding: 1.33rem 2.56rem;

      @include breakpoint.media('<tablet') {
        flex-wrap: wrap-reverse;
        row-gap: 1.33rem;
      }

      &:not(:first-child) {
        border-left: 0.056rem dashed variable.$grey-400;

        @include breakpoint.media('<desktop') {
          border-left: 0;
          border-top: 0.056rem dashed variable.$grey-400;
        }
      }

      @include breakpoint.media('<desktop') {
        justify-content: space-between;

        column-gap: 2.56rem;
      }
    }

    .upload-logo {
      text-align: center;
      .title {
        padding-bottom: 0.56rem;
        @extend .xs;
        font-weight: medium;
      }
    }

    .full-logo {
      .drag-and-drop-container input {
        min-width: 210px;
      }
    }

    .short-logo {
      .drag-and-drop-container input {
        min-width: 210px;
      }
    }
  }

  .logo-display {
    max-height: 100px;
    object-fit: contain;
  }

  .change-password {
    @include layout.flex($align: center);
    flex-direction: column;
    gap: 1rem;
  }
}

.upload-document-modal,
.change-logo-card {
  .file-upload-field {
    @include layout.flex($align: center, $justify: center);
    flex-direction: column;
    width: 320px;

    .icon {
      width: 1.5rem;
      color: variable.$primary-col-500;
    }

    p {
      margin-top: 00.33rem;
      color: variable.$black;
      @extend .sm;
    }

    button {
      background-color: variable.$secondary-col-500;
      color: variable.$white;
      margin-top: 0.56rem;
    }
  }
}

.profile-management .card-heading-container {
  @include layout.flex($align: center, $justify: space-between);

  .edit-details-button {
    @include layout.flex($align: center, $justify: center);
    gap: 0.44rem;
    .icon {
      color: variable.$info-500;
      width: 0.85rem;
      @include layout.flex($align: center, $justify: center);
    }
  }
}

.account-management-card {
  .detail-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.22rem;
    @include breakpoint.media('<tablet') {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .detail-field {
      display: flex;
      flex-direction: column;
      gap: 0.44rem;

      .field-title {
        @extend .xxs;
        font-weight: 400;
        color: variable.$grey-400;
        text-transform: uppercase;
      }

      p {
        @extend .sm;
        color: variable.$black;
      }
    }
  }
}
