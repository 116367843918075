@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/base/typography' as typography;
@use '/src/styles/base/variables' as variable;

.all-app-page {
  .page-header-container {
    @include layout.flex($justify: space-between);
    width: 100%;

    button {
      padding: 0.5rem 0.75rem;

      .create-app-icon {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  .apps-table {
    .app-logo {
      width: 32px;
      height: 32px;
      border-radius: variable.$rounded-full;
    }

    .app-name-col {
      @include layout.flex($align: center);
      gap: 0.75rem;
    }

    .service-col {
      width: 45%;
    }

    .services-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
  }
}
