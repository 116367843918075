@use '/src/styles/base/_variables.scss' as variable;
@use '/src/styles/mixins/_layout.scss' as layout;

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  overflow: hidden;
  height: 2.1875rem;
}

.page-link {
  position: relative;
  display: block;
  color: variable.$text-text-button-brand-tiffanyblue;
  width: 2.1875rem;
  height: 100%;
  text-decoration: none;
  background-color: variable.$white;
  border: 1px solid #dee2e6;
  border-radius: 0;

  &:hover {
    color: variable.$text-text-button-brand-tiffanyblue;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }

  &:focus {
    color: variable.$text-text-button-brand-tiffanyblue;
    background-color: #e9ecef;
    outline: 0;
  }

  .icon {
    display: inline-flex;
  }
}

.page-item {
  &.active .page-link {
    color: variable.$text-text-button-brand-tiffanyblue;
    border: 0;
  }

  &.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: variable.$grey-400;
    border-color: #dee2e6;
  }
}

.pagination-box {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .showing-box {
    position: relative;
    font-size: 0.875rem;
  }

  .pagination-nav-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .pagination {
    margin-bottom: 0;

    .page-link {
      font-weight: 500;
      background-color: transparent;
      border: none;
      padding: 0.325rem 0.75rem;
    }
    .page-link:disabled {
      opacity: 0.31;
    }
    .page-link:hover {
      opacity: 1;
      font-weight: 500;
    }
    .page-link:focus {
      outline: 0;
      box-shadow: unset;
    }
    .active .page-link {
      opacity: 1;
      font-weight: 500;
      color: variable.$text-text-button-brand-tiffanyblue;
      border: 1px solid variable.$border-border-default;
    }
    .prev-btn,
    .next-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid variable.$border-border-default;
    }
    .prev-btn .page-link,
    .next-btn .page-link {
      opacity: 1;
    }
    .prev-btn:hover,
    .next-btn:hover {
      color: rgba(17, 41, 69, 0.8);
      background: rgba(17, 41, 69, 0.2);
    }
    .prev-btn {
      transform: rotate(180deg);
    }
  }

  .per-page {
    display: inline-block;
    width: 68px;

    .react-select__value-container {
      padding: 0 !important;
    }

    .react-select__control {
      height: 0;
    }

    .react-select__indicator {
      // padding-right: 0.25rem !important;
      padding-left: 0 !important;
    }

    .react-select__single-value {
      padding-left: 0.25rem !important;
      text-align: center;
    }
  }
}

@media (max-width: 575.98px) {
  .pagination-box {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;

    .showing-box {
      margin-top: 0rem;
    }

    .pagination-nav-container {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
