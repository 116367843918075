@use '/src/styles/base/variables.scss' as variable;
@use '/src/styles/base/typography' as typography;

.login-page {
  width: 100%;

  .auth-page-heading-icon {
    margin-bottom: 1.5rem;
  }

  h6 {
    color: variable.$text-text-brand-blue;
    margin-bottom: 0.375rem;
  }

  form {
    margin-top: 1.5rem;

    .input-container {
      margin-top: 1rem;
    }
    .error-message {
      @extend .sm;
      color: variable.$error-500;
      line-height: 1px;
    }

    .forgot-pwd {
      margin-top: 1rem;
    }

    .auth-button {
      width: 100%;
      margin-top: 1.5rem;
    }
  }

  .sign-up {
    margin: 1.5rem auto 0;
    @extend .xs;
  }
}
