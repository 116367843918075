input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='date']:not(input[type='date']:focus) {
  color: transparent;
}
input[type='date']:active
  input[type='date']:focus
  input[type='date']:focus-within
  input[type='date']:focus-visible {
  color: currentColor;
}
