@use '/src/styles/base/variables.scss' as variable;
@use '/src/styles/mixins/layout.scss' as layout;
@use '/src/styles/mixins/breakpoints.scss' as breakpoint;

$main-content-padding-top: 8rem;

.auth-layout {
  @include layout.flex($justify: flex-end);
  width: 100%;
  position: relative;

  .svg-section-1 {
    width: 55.56%;
    min-height: 100vh;

    position: fixed;
    left: 0;
    background: variable.$background-background-neutral-bold-pressed;

    .logo {
      width: 6.75rem;
      color: variable.$white;
      position: absolute;
      top: 2.5rem;
      left: 2.5rem;
    }

    .illustration-container {
      height: 100vh;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .illustration-content {
        padding: 20vh 5rem 0;
        @include breakpoint.media('<LGdesktop') {
          padding: 20vh 2.5rem 0;
        }
      }
      .heading {
        color: variable.$white;
      }

      .message-list-container {
        margin-top: 0.75rem;
      }

      .message-list {
        @include layout.flex($align: center, $justify: flex-start);
        gap: 0.25rem;
        color: #ffffffde;
        font-size: 1.125rem;
      }

      ul {
        padding-top: 1rem;

        li {
          &:not(:first-child) {
            padding-top: 0.625rem;
          }
        }
      }

      .illustration {
        width: 100%;
        bottom: -5vh;
        margin-top: 8vh;
        margin-left: 5rem;

        @include breakpoint.media('<LGdesktop') {
          margin-left: 3.5rem;
        }
      }
    }

    // @include breakpoint.media('<LGdesktop') {
    //   padding-top: 8rem;
    // }
    @include breakpoint.media('<desktop') {
      display: none;
    }

    @include breakpoint.media('>=desktop') {
      & ~ .form-section-container .main-content {
        padding-top: 12.5rem;
      }
      & ~ .form-section-container .main-content-long {
        padding-top: 7.5rem;
      }
    }
  }

  .background-mesh {
    position: absolute;
    top: 0;
    right: 0;
  }

  .form-section-container {
    min-height: 100vh;
    background-color: #fff;
    position: relative;

    width: 44.44%;
    @include breakpoint.media('<desktop') {
      width: 100%;
    }

    @include layout.flex($align: center, $justify: space-between);
    flex-direction: column;

    .main-content {
      min-height: 80vh;
      width: 100%;
      max-width: 400px;
      @include layout.flex($align: center, $justify: flex-start);
      flex-direction: column;
      overflow-x: hidden;

      @include breakpoint.media('<LGdesktop') {
        padding: 12.5rem 3.25rem 2.22rem;
      }
      @include breakpoint.media('<desktop') {
        padding: 0 2.5rem 1rem;
        min-height: 40vh;
      }
      @include breakpoint.media('<tablet') {
        padding: 0 2.5rem 2rem;
      }
    }

    .logo {
      display: none;
      width: 100%;
      padding-left: 2.5rem;
      img {
        width: 6.75rem;
        padding-top: calc($main-content-padding-top / 2);
        color: variable.$primary-col-600;
      }
      @include breakpoint.media('<desktop') {
        display: block;
      }
    }

    .form-section {
      width: 100%;
      @include breakpoint.media('<desktop') {
        padding-top: 0;
        width: 100%;
        max-width: 350px;
        margin: 0 auto;
      }

      @include layout.flex($align: flex-start, $justify: center);
      flex-direction: column;
    }

    .action-row {
      width: 100%;
      @include layout.flex($align: center, $justify: space-between);

      padding: 3rem 2rem 1.5rem;
      @include breakpoint.media('<desktop') {
        padding: 0 2rem 2rem;
      }
    }
  }
}
