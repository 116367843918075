@use '/src/styles/base/variables.scss' as variable;
@use '../login/login' as loginStyle;

.forgot-password-page {
  @extend .login-page;

  .resend-email-btn {
    margin-top: 1rem;
    width: 100%;
  }
}
