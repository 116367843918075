@use '/src/styles/base/variables' as variable;
@use '/src/styles/base/typography.scss' as typography;
@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;

.analytics-grid {
  display: grid;
  gap: 1.25rem;
  grid-template-columns: 0.587fr 0.413fr;

  @include breakpoint.media('<tablet') {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }
}

.wallet-page {
  .wallet-balance-card-container {
    border-radius: 0.75rem;
    overflow: hidden;
    .wallet-balance-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2.125rem;
      height: 100%;
      background-image: url('../../../assets/illustrations/wallet-card-background.png');
      background-size: cover;
      background-repeat: no-repeat;

      .wallet-amount {
        font-size: 2.125rem;
      }
    }
  }

  .billing-account-tooltip {
    margin-left: 0.33rem;
    width: 1rem;
    height: 1rem;
  }

  .billing-account-info {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    row-gap: 1rem;
    @include breakpoint.media('>tablet') {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @extend .sm;

    .label {
      @extend .xs;
    }

    .bill-account-data {
      .acc-num {
        display: flex;
        flex-wrap: nowrap;
        .icon {
          width: 0.89rem;
          display: inline-block;
          margin-left: 0.22rem;
          button {
            color: inherit;
          }
        }
      }
    }
  }

  .recent-transactions-table {
    margin-top: 1.61rem;

    .status-chip {
      @extend .xs;
      text-transform: capitalize;

      &[data-status='successful'] {
        color: variable.$success-500;
        background-color: variable.$success-100;
      }
      &[data-status='failed'] {
        color: variable.$error-500;
        background-color: variable.$error-100;
      }
    }
  }
}
