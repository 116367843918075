@use "/src/styles/base/variables" as variable;
@use "/src/styles/base/typography" as typography;
@use "/src/styles/mixins/layout" as layout;

.fund-wallet-status {
  width: 100%;
  height: inherit;
  @include layout.flex($justify: space-between);
  flex-direction: column;
  margin-top: 50%;

  div {
    width: 100%;
  }

  .status {
    min-height: 85%;
    @include layout.flex($justify: center, $align: center);
    flex-direction: column;

    .icon {
      @include layout.flex($justify: center, $align: center);
    }

    .message {
      text-align: center;
      max-width: 340px;
      margin-top: 1.11rem;
      color: variable.$grey-700;
    }
  }

  button {
    margin-top: 2.22rem;
    width: 100%;
    padding: 0.667rem 1.11rem;
    @extend p;
  }
}
