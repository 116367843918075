@use '/src/styles/base/variables' as variable;
@use '/src/styles/mixins/layout' as layout;

.chip {
  @include layout.flex($align: center, $justify: center);
  width: fit-content;
  gap: 0.44rem;
  padding: 0.125rem 0.325rem;
  border-radius: 0.25rem;
  white-space: nowrap;

  &.no-background {
    background-color: #ffffff00 !important;
  }

  .indicator {
    @include layout.flex($align: center, $justify: center);
    background-color: currentColor;
    width: 0.44rem;
    height: 0.44rem;
    border-radius: variable.$rounded-full;
  }

  &[data-status='200'] {
    background-color: variable.$background-background-success-default;
    color: variable.$text-text-green;
  }

  &[data-method='GET'] {
    background-color: variable.$background-background-success-default;
    color: variable.$text-text-link;
  }

  &[data-status='500'] {
    background-color: variable.$background-background-warning-default;
    color: variable.$text-text-orange;
  }
  &[data-method='POST'] {
    background-color: variable.$background-background-warning-default;
    color: variable.$text-text-orange;
  }
  &[data-method='PATCH'] {
    background-color: variable.$background-background-warning-default;
    color: variable.$text-text-orange;
  }
  &[data-method='PUT'] {
    background-color: variable.$background-background-warning-default;
    color: variable.$text-text-orange;
  }

  &[data-status='404'] {
    background-color: variable.$background-background-danger-default;
    color: variable.$text-text-red;
  }
  &[data-status='DELETE'] {
    background-color: variable.$background-background-danger-default;
    color: variable.$text-text-red;
  }

  &[data-status='SUCCESS'] {
    background-color: variable.$background-background-success-default;
    color: variable.$text-text-green;
  }

  &[data-status='ERROR'] {
    background-color: variable.$background-background-danger-default;
    color: variable.$text-text-red;
  }

  &[data-status='WARNING'] {
    background-color: variable.$background-background-warning-default;
    color: variable.$text-text-orange;
  }

  &[data-status='BLUE'] {
    background-color: variable.$background-background-info-default;
    color: variable.$background-background-default;
    &.no-background {
      color: variable.$text-text-link;
    }
  }
  &[data-status='YELLOW'] {
    background-color: variable.$semantics-yellow-yellow-500;
    color: variable.$background-background-default;
    &.no-background {
      color: variable.$semantics-yellow-yellow-500;
    }
  }
  &[data-status='GREEN'] {
    background-color: variable.$background-background-success-bold-default;
    color: variable.$background-background-default;
    &.no-background {
      color: variable.$background-background-success-bold-default;
    }
  }
  &[data-status='ORANGE'] {
    background-color: variable.$background-background-warning-bold-default;
    color: variable.$background-background-default;
    &.no-background {
      color: variable.$background-background-warning-bold-default;
    }
  }
  &[data-status='RED'] {
    background-color: variable.$background-background-danger-bold-default;
    color: variable.$background-background-default;
    &.no-background {
      color: variable.$background-background-danger-bold-default;
    }
  }
  &[data-status='NEUTRAL'] {
    background-color: variable.$background-background-neutral-default;
    color: variable.$text-text-primary;
    &.no-background {
      color: variable.$background-background-default;
    }
  }
}
