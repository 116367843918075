@use "/src/styles/base/variables" as variable;
@use "/src/styles/base/typography" as typography;
@use "/src/styles/mixins/layout" as layout;
@use "/src/styles/mixins/breakpoints" as breakpoint;

.fund-wallet-form {
  width: 100%;
  height: inherit;
  @include layout.flex($justify: space-between);
  flex-direction: column;
  gap: 1rem;

  .form-section {
    width: 100%;

    .input-error {
      color: variable.$error-500;
      @extend .xs;
    }
  }

  .amount-container {
    margin-top: 2.22rem;
    @include layout.flex($align: center);
    border: 2px solid variable.$primary-col-500;
    border-radius: variable.$input-radius;

    .currency {
      color: variable.$grey-800;
      padding: 0.567rem 0.67rem 0.567rem 0.89rem;
      border-right: 2px solid variable.$primary-col-500;
      @extend .xs;
    }
    .input-container {
      width: 100%;
    }

    .input-field-container {
      background: variable.$white;
      &:focus-within {
        border: 0;
      }

      input {
        padding-top: 0;
        padding-bottom: 0;
        @extend .xs;
      }
    }
  }

  .agreement-label {
    @extend .xxs;
    color: variable.$grey-500;
  }

  button[type="submit"] {
    margin-top: 2.22rem;
    width: 100%;
    @extend p;
    padding: 0.67rem 0.89rem;

    @include breakpoint.media("<desktop") {
      margin: 2.22rem 0 1.11rem;
    }
  }
}

.fund-wallet-pane {
  .status-message {
    .amount {
      white-space: nowrap;
    }
  }
  button {
    margin-bottom: 0;
  }
}
