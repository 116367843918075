@use '/src/styles/base/_variables.scss' as variable;
@use '/src/styles/mixins/_layout' as layout;
@use '/src/styles/mixins/_breakpoints' as breakpoint;
@use '/src/styles/base/typography' as typography;

.view-role-page {
  .event-details-card {
    .card-heading-container {
      @include layout.flex($align: center, $justify: space-between);
    }

    .detail-row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2.22rem;
      @include breakpoint.media('<tablet') {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }

      .detail-field {
        display: flex;
        flex-direction: column;
        gap: 0.44rem;

        .field-title {
          @extend .xs;
          font-weight: 400;
          text-transform: uppercase;
        }

        p {
          @extend .sm;
          color: variable.$text-text-primary;
        }
      }
    }
  }
}
