@use '/src/styles/base/variables' as variable;
@use '/src/styles/base/typography.scss' as typography;
@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;

.in-app-layout {
  min-height: 100vh;
  width: 100%;

  .topbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: variable.$topbar-index;

    @include breakpoint.media('>=desktop') {
      padding-left: variable.$sidebar-width;
    }
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    overflow: inherit;
    z-index: variable.$sidebar-index;
    transition: all 0.2s ease-in-out;

    @include breakpoint.media('<desktop') {
      left: -100%;
    }

    &.open {
      left: 0;
    }
  }

  .main-content {
    background-color: variable.$body-bg;
    min-height: 100vh;
    margin-left: variable.$sidebar-width;
    padding: calc(variable.$topbar-height + variable.$body-padding-desktop)
      variable.$body-horizontal-padding-desktop 3rem;

    @include breakpoint.media('<desktop') {
      margin-left: 0;
      padding: (variable.$topbar-height + variable.$body-padding-tablet + 1rem)
        variable.$body-padding-tablet 2rem;
    }
    @include breakpoint.media('<desktop') {
      margin-left: 0;
      padding: (variable.$topbar-height + variable.$body-padding-mobile + 1rem)
        variable.$body-padding-mobile 2rem;
    }
  }

  [class='sidebar collapsed'] + .topbar {
    padding-left: variable.$sidebar-collapsed-width;
    @include breakpoint.media('<desktop') {
      padding-left: 0;
    }
  }

  [class='sidebar collapsed'] ~ .main-content {
    margin-left: variable.$sidebar-collapsed-width;
    @include breakpoint.media('<desktop') {
      margin-left: 0;
    }
  }
}
