@use '/src/styles/base/variables.scss' as variable;
@use '/src/styles/base/typography.scss' as typography;
@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;

.analytics_grid {
  display: grid;
  gap: 1.25rem;
  grid-template-columns: repeat(2, minmax(256px, 1fr));
  @include breakpoint.media('<tablet') {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .no_activity_card {
    background-color: variable.$background-background-disabled;
    color: variable.$text-text-tetiary;
    height: 100%;
    @include layout.flex($align: center, $justify: center);
  }
}

.api_call_chart_container {
  height: 100%;
  .api_call_chart {
    height: calc(100% - 3.25rem);
  }
}

.table_mobile_card,
.recent_api_call {
  margin-top: 1.25rem;
}

.recent_api_call {
  .request-id-field {
    max-width: 12rem;
  }
}
