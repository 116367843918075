@use '/src/styles/base/variables' as variable;
@use '/src/styles/mixins/breakpoints' as breakpoint;
@use '/src/styles/mixins/layout.scss' as layout;

.card {
  background: variable.$background-background-default;
  border-radius: variable.$card-radius;
  border: 0.840603px solid;
  border-color: variable.$border-border-disabled;

  .card_body,
  .card_heading_container,
  .card_footer_container {
    padding: variable.$card-padding-top variable.$card-padding-left;
    width: 100%;
  }

  .card_heading_container {
    &:not(.card_heading_collapsed) {
      border-bottom: 1px solid variable.$border-border-disabled;
    }
  }

  .card_heading_collapsed {
    .collapse_icon {
      transform: rotate(180deg);
    }
  }

  .card_header_title {
    color: variable.$text-text-primary;
  }

  .card_header {
    @include layout.flex($align: center, $justify: space-between);

    .right_elements {
      @include layout.flex($align: center, $justify: space-between);
      gap: 0.75rem;
    }
  }

  .card_footer_container {
    border-top: 1px solid variable.$border-border-disabled;
  }
}
