@use '/src/styles/base/variables.scss' as variable;
@use '/src/styles/mixins/layout.scss' as layout;
@use '/src/styles/base/typography' as typography;

$input-top-padding: 0.75rem 0.75rem;
$placeholder-color: variable.$grey-500;
$background-color: variable.$background-background-default;

$label-z-index: 1;

.input-container,
.click-to-upload-container,
.pin-field-container {
  position: relative;
  .input-label {
    position: absolute;
    top: 50%;
    left: 0.75rem;
    transform: translateY(-50%);
    @extend .sm;
    display: block;
    color: variable.$text-text-disabled;
    font-weight: 400;
    z-index: $label-z-index;
    transition: 0.2s all;
    padding: 0 2px;
    pointer-events: none;
  }

  .input-error {
    border-color: variable.$error-500;
    background-color: variable.$white;
  }
  .input-success {
    border-color: variable.$success-500;
    background-color: variable.$white;
  }

  .error-message {
    @extend .xs;
    color: variable.$error-500;
  }
  .success-message {
    @extend .xs;
    color: variable.$success-500;
  }

  &:focus-within {
    border-color: variable.$border-border-focused;
    & .input-label {
      top: 0;
      background-color: variable.$white;
      color: variable.$border-border-focused;
      font-weight: 500;
      @extend .xxs;
    }
  }

  .active {
    top: 0;
    background-color: variable.$white;
    font-weight: 500;
    @extend .xxs;
  }
}

.input-field-container {
  width: 100%;
  position: relative;
  border: 1px solid;
  border-color: variable.$border-border-default;
  border-radius: variable.$input-radius;
  background-color: transparent;
  @include layout.flex($align: center);

  &:focus-within {
    background-color: transparent;
    border-color: variable.$border-border-focused;
  }

  input {
    @extend .sm;
    width: 100%;
    padding: $input-top-padding;
    border-radius: variable.$input-radius;
    background-color: transparent;
    border: 0;
    outline: 0;

    &:active {
      border: 0;
      outline: 0;
    }

    &:focus {
      border: 0;
      outline: 0;
    }

    &::placeholder {
      color: $placeholder-color;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px white inset !important;
    }
  }

  &.icon-right {
    position: relative;
    input {
      margin-right: 2rem;
    }
    .icon {
      position: absolute;
      top: 50%;
      right: 0.89rem;
      transform: translateY(-50%);
    }
  }

  &.icon-left {
    position: relative;
    input {
      margin-left: 2rem;
    }
    .icon {
      position: absolute;
      top: 50%;
      left: 0.89rem;
      transform: translateY(-50%);
    }
  }

  .password-icon {
    cursor: pointer;
  }
}
