@use '/src/styles/base/variables.scss' as variable;

.switch-store-container {
  display: flex;
  flex-direction: column;

  .store-container {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    cursor: pointer;
    border-bottom: 1px solid variable.$grey-100;

    .store {
      display: flex;
      align-items: center;
      gap: 1rem;
      text-align: left;
    }

    .store-image {
      display: flex;
      align-items: center;
      width: 3rem;
      height: 3rem;
      outline: 0.02rem solid variable.$primary-col-500;
      border-radius: 0.5rem;
      padding: 0.22rem;
    }
    .active-store {
      float: right;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
