@use '/src/styles/base/variables' as variable;
@use '/src/styles/base/typography' as typography;
@use '/src/styles/mixins/breakpoints' as breakpoints;
@use '/src/styles/mixins/layout' as layout;

.table-container {
  padding: 0;

  .table-heading {
    padding: 0 1rem;
    @include layout.flex($align: center, $justify: space-between);

    .title {
      font-weight: bold;
    }

    .filter-btn,
    .table-header-additional-content,
    .title {
      margin: variable.$card-padding-top 0;
    }

    & + hr {
      border-top: 0.0567rem solid
        variable.$background-background-neutral-default;
      background-color: variable.$background-background-neutral-default;
      margin-bottom: 0.67rem;
    }
  }

  table {
    width: 100%;
    @include breakpoints.media('<desktop') {
      min-width: max-content;
    }
    text-align: left;
    border-collapse: collapse;

    thead {
      background-color: variable.$background-background-neutral-default;
    }

    tbody {
      &[data-loading='true'] {
        position: relative;
        height: 100px;

        .table-spinner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &[data-rowicon='true'] {
      td {
        &:nth-child(2) {
          padding-left: 0;
        }
      }
    }

    th {
      padding: 0.75rem 1rem;
      font-weight: 600;
      color: variable.$text-text-primary;
      padding-left: variable.$card-padding-left;
      text-transform: uppercase;
      @extend .xs;
    }

    .table-row {
      @extend .sm;

      &:not(:last-child) {
        border-bottom: 1px solid variable.$background-background-neutral-default;
      }

      .table-item-container {
        padding: 0;
      }

      td {
        padding: 1.125rem 0 1.125rem variable.$card-padding-left;
        color: variable.$grey-800;
      }

      .table-row-icon {
        padding-left: variable.$card-padding-left;
      }

      .table-more-field {
        padding-right: 1.61rem;
        text-align: right;
        float: right;
        vertical-align: middle;
      }

      &.clickable {
        cursor: pointer;
      }
    }

    .dropdown-menu {
      transform-origin: top right;

      &:last-child {
        bottom: 0;
        right: 50%;
        transform-origin: bottom right;
      }
    }
  }

  .table-empty-state-conatiner {
    border-top: 1px solid variable.$border-border-disabled;
    padding: 4rem;
  }

  .table-filter-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.card {
  .table-container {
    .table-wrapper {
      // overflow-x: auto;
      // overflow-y: visible;
      max-width: 100%;
    }
  }
}
