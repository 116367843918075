@use '/src/styles/base/variables' as variable;

.page_heading_container {
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;

  h6 {
    color: variable.$text-text-brand-blue;
    margin-bottom: 0.125rem;
  }

  .page_heading_rightElement {
    display: flex;
    justify-content: flex-end;
    gap: 0.25rem;
  }
}
