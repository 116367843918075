$brand-blue-100: #e9f2ff;
$brand-blue-blue-200: #cce0ff;
$brand-blue-blue-300: #85b8ff;
$brand-blue-blue-400: #579dff;
$brand-blue-blue-500: #388bff;
$brand-blue-blue-600: #1d7afc;
$brand-blue-blue-700: #0c66e4;
$brand-blue-blue-800: #0055cc;
$brand-blue-blue-900: #13367d;
$brand-blue-blue1000: #1c2b41;
$brand-tiffany-blue-tiffany-blue1000: #1d5454;
$brand-tiffany-blue-tiffanyblue-100: #f7fcfc;
$brand-tiffany-blue-tiffanyblue-200: #e8f7f7;
$brand-tiffany-blue-tiffanyblue-300: #c6ebeb;
$brand-tiffany-blue-tiffanyblue-400: #a4e0e0;
$brand-tiffany-blue-tiffanyblue-500: #7bd2d3;
$brand-tiffany-blue-tiffanyblue-600: #5fc8c8;
$brand-tiffany-blue-tiffanyblue-700: #40baba;
$brand-tiffany-blue-tiffanyblue-800: #349898;
$brand-tiffany-blue-tiffanyblue-900: #287676;
$neutrals-dark-mode-alpha-dark-neutral-250a: #c8e1f91a;
$neutrals-dark-mode-alpha-darkneutral-100a: #bcd6f00a;
$neutrals-dark-mode-alpha-darkneutral-100a-: #03040442;
$neutrals-dark-mode-alpha-darkneutral-200a: #a1bdd914;
$neutrals-dark-mode-alpha-darkneutral-300a: #c8e1f929;
$neutrals-dark-mode-alpha-darkneutral-350a: #c3defe33;
$neutrals-dark-mode-alpha-darkneutral-400a: #bfdbf847;
$neutrals-dark-mode-alpha-darkneutral-500a: #9bb4ca80;
$neutrals-dark-mode-solid-darkneutral-100: #1d2125;
$neutrals-dark-mode-solid-darkneutral-1000: #c7d1db;
$neutrals-dark-mode-solid-darkneutral-100-: #101214;
$neutrals-dark-mode-solid-darkneutral-1100: #dee4ea;
$neutrals-dark-mode-solid-darkneutral-200: #22272b;
$neutrals-dark-mode-solid-darkneutral-250: #282e33;
$neutrals-dark-mode-solid-darkneutral-300: #2c333a;
$neutrals-dark-mode-solid-darkneutral-350: #38414a;
$neutrals-dark-mode-solid-darkneutral-400: #454f59;
$neutrals-dark-mode-solid-darkneutral-50: #161a1d;
$neutrals-dark-mode-solid-darkneutral-500: #596773;
$neutrals-dark-mode-solid-darkneutral-600: #738496;
$neutrals-dark-mode-solid-darkneutral-700: #8c9bab;
$neutrals-dark-mode-solid-darkneutral-800: #9fadbc;
$neutrals-dark-mode-solid-darkneutral-900: #b6c2cf;
$neutrals-light-mode-alpha-neutral-100a: #091e4208;
$neutrals-light-mode-alpha-neutral-200a: #091e420f;
$neutrals-light-mode-alpha-neutral-300a: #091e421f;
$neutrals-light-mode-alpha-neutral-400a: #091e424f;
$neutrals-light-mode-alpha-neutral-500a: #091e427d;
$neutrals-light-mode-solid-neutral-0: #ffffff;
$neutrals-light-mode-solid-neutral-100: #f7f8f9;
$neutrals-light-mode-solid-neutral-1000: #172b4d;
$neutrals-light-mode-solid-neutral-200: #f1f2f4;
$neutrals-light-mode-solid-neutral-300: #dcdfe4;
$neutrals-light-mode-solid-neutral-400: #b3b9c4;
$neutrals-light-mode-solid-neutral-500: #8590a2;
$neutrals-light-mode-solid-neutral-600: #758195;
$neutrals-light-mode-solid-neutral-700: #626f86;
$neutrals-light-mode-solid-neutral-800: #44546f;
$neutrals-light-mode-solid-neutral-900: #2c3e5d;
$neutrals-light-mode-solid-neutral1100: #091e42;
$semantics-green-green-100: #dcfff1;
$semantics-green-green-200: #baf3db;
$semantics-green-green-300: #7ee2b8;
$semantics-green-green-400: #4bce97;
$semantics-green-green-500: #2abb7f;
$semantics-green-green-600: #22a06b;
$semantics-green-green-700: #1f845a;
$semantics-green-green-800: #216e4e;
$semantics-green-green-900: #164b35;
$semantics-green-green1000: #1c3329;
$semantics-lime-lime-100: #efffd6;
$semantics-lime-lime-200: #d3f1a7;
$semantics-lime-lime-300: #b3df72;
$semantics-lime-lime-400: #94c748;
$semantics-lime-lime-500: #82b536;
$semantics-lime-lime-600: #6a9a23;
$semantics-lime-lime-700: #5b7f24;
$semantics-lime-lime-800: #4c6b1f;
$semantics-lime-lime-900: #37471f;
$semantics-lime-lime1000: #28311b;
$semantics-magenta-magenta-100: #ffecf8;
$semantics-magenta-magenta-200: #fdd0ec;
$semantics-magenta-magenta-300: #f797d2;
$semantics-magenta-magenta-400: #e774bb;
$semantics-magenta-magenta-500: #da62ac;
$semantics-magenta-magenta-600: #cd519d;
$semantics-magenta-magenta-700: #ae4787;
$semantics-magenta-magenta-800: #943d73;
$semantics-magenta-magenta-900: #50253f;
$semantics-magenta-magenta1000: #3d2232;
$semantics-maroon-maroon-100: #fcf8f9;
$semantics-maroon-maroon-200: #f7e9ed;
$semantics-maroon-maroon-300: #eac7d1;
$semantics-maroon-maroon-400: #dea6b6;
$semantics-maroon-maroon-500: #d2849a;
$semantics-maroon-maroon-600: #bd4b6a;
$semantics-maroon-maroon-700: #b64364;
$semantics-maroon-maroon-800: #953752;
$semantics-maroon-maroon-900: #732b3f;
$semantics-maroon-maroon1000: #521e2d;
$semantics-orange-orange-100: #fff3eb;
$semantics-orange-orange-200: #fedec8;
$semantics-orange-orange-300: #fec195;
$semantics-orange-orange-400: #fea362;
$semantics-orange-orange-500: #f38a3f;
$semantics-orange-orange-600: #e56910;
$semantics-orange-orange-700: #c25100;
$semantics-orange-orange-800: #a54800;
$semantics-orange-orange-900: #702e00;
$semantics-orange-orange1000: #38291e;
$semantics-purple-purple-100: #f3f0ff;
$semantics-purple-purple-200: #dfd8fd;
$semantics-purple-purple-300: #b8acf6;
$semantics-purple-purple-400: #9f8fef;
$semantics-purple-purple-500: #8f7ee7;
$semantics-purple-purple-600: #8270db;
$semantics-purple-purple-700: #6e5dc6;
$semantics-purple-purple-800: #5e4db2;
$semantics-purple-purple-900: #352c63;
$semantics-purple-purple1000: #2b273f;
$semantics-red-red-100: #ffeceb;
$semantics-red-red-200: #ffd5d2;
$semantics-red-red-300: #fd9891;
$semantics-red-red-400: #f87168;
$semantics-red-red-500: #f15b50;
$semantics-red-red-600: #e2483d;
$semantics-red-red-700: #c9372c;
$semantics-red-red-800: #ae2e24;
$semantics-red-red-900: #5d1f1a;
$semantics-red-red1000: #42221f;
$semantics-teal-teal-100: #e7f9ff;
$semantics-teal-teal-200: #c6edfb;
$semantics-teal-teal-300: #9dd9ee;
$semantics-teal-teal-400: #6cc3e0;
$semantics-teal-teal-500: #42b2d7;
$semantics-teal-teal-600: #2898bd;
$semantics-teal-teal-700: #227d9b;
$semantics-teal-teal-800: #206a83;
$semantics-teal-teal-900: #164555;
$semantics-teal-teal1000: #1e3137;
$semantics-yellow-100: #fff7d6;
$semantics-yellow-yellow-200: #f8e6a0;
$semantics-yellow-yellow-300: #f5cd47;
$semantics-yellow-yellow-400: #e2b203;
$semantics-yellow-yellow-500: #cf9f02;
$semantics-yellow-yellow-600: #b38600;
$semantics-yellow-yellow-700: #946f00;
$semantics-yellow-yellow-800: #7f5f01;
$semantics-yellow-yellow-900: #533f04;
$semantics-yellow-yellow1000: #332e1b;
