@use "/src/styles/mixins/layout" as layout;
@use "/src/styles/mixins/breakpoints" as breakpoint;
@use "/src/styles/base/typography" as typography;
@use "/src/styles/base/variables" as variable;

.configuration-field {
  // &:first- {
  //   border-top: 0.056rem solid variable.$grey-300;
  //   padding-top: 0.89rem;
  //   // margin-top: 0.89rem;
  // }

  .configuration-field-title{
    @include layout.flex($align: center);
    gap: 1rem;
    text-transform: capitalize;
  }

  .styled-checkbox {
    & + label:before {
      min-width: 0.78rem;
      height: 00.78rem;
    }

    & + label:after {
      left: 0.187rem;
    }
  }

  .config-title {
    @extend .xxs;
    font-weight: bold;
    text-transform: capitalize;
  }

  .config-input-fields {
    @include layout.flex($justify: space-between, $align: flex-end);
    // gap: 1.5rem;
    @extend .xxs;
    margin-top: 1rem;

    .input-container {
      width: 100%;

      input{
        @extend .xxs;
      }
    }

    .select-container {
      width: 100% !important;
      .react-select__placeholder {
        @extend .xxs;
        color: variable.$grey-400 !important;
        font-weight: 400
      }
      .react-select__control {
        @extend .xs;
        border: 0 !important;
        box-shadow: none !important;
        background-color: variable.$grey-100 !important;
        border-radius: variable.$input-radius !important;
      }
    }

    .range-fields {
      @include layout.flex($align: center);
      gap: 0.22rem;
      width: 65%;

      .styled-checkbox-container {
        display: none !important;
        // this removes the checkbox built into the select component
      }

      .react-select__control {
        @extend .xxs;
        padding-left: 0;
      }
    }

    .score-fields {
      @include layout.flex($align: flex-end, $justify: flex-end);
      gap: 0.22rem;
      max-width: 30%;

      .max {
        white-space: nowrap;
        margin-bottom: 0.56rem;
        color: variable.$grey-400;
      }
    }
  }
}
