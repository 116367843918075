@use '/src/styles/base/variables' as variable;

.empty-table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 250px;
  text-align: center;

  .illustration {
    height: 300px;
    width: auto;
  }

  .empty-table-message {
    font-weight: 600;
    margin-top: 1.25rem;
  }
  .empty-table-add-message {
    color: variable.$text-text-secondary;
  }
}
