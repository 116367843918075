@use "/src/styles/mixins/layout" as layout;
@use "/src/styles/mixins/breakpoints" as breakpoint;
@use "/src/styles/base/typography" as typography;
@use "/src/styles/base/variables" as variable;

.decision-setting-field {
  @include layout.flex($align: center, $justify: space-between);
  column-gap: 0.5rem;
  @extend .xxs;

  &:not(:first-child) {
    padding-top: 0.89rem;
  }

  .decision-setting-title {
    @include layout.flex($align: center);
    gap: 1rem;
    text-transform: uppercase;
    color: variable.$black;
  }

  .decision-setting-toggle {
    @include layout.flex($align: center);
    column-gap: 0.5rem;
    color: variable.$grey-400;

    .toggle-field .switch {
      height: 0.65rem;
      width: 1.25rem;
    }

    .toggle-field .slider:before {
      height: 0.55rem;
      width: 0.55rem;
    }
  }

  .decision-setting-input {
    width: 4.056rem;
  }
  .decision-setting-select {
    width: 100%;
    max-width: 12.056rem;
  }
}
