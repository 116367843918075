@use "/src/styles/mixins/layout" as layout;

.events-table {
  // .who-field,
  // .when-field,
  // .event-field {
  //   width: auto;
  //   max-width: 30%;
  // }

  .event-field {
    @include layout.flex($align: center);
    gap: 0.22rem;

    .chip {
      background-color: #d7e0f4;
      border-radius: 0.11rem;
    }
  }
}
