@use '/src/styles/mixins/layout.scss' as layout;
@use '/src/styles/base/variables' as variable;

.close_btn {
  color: variable.$grey-400;
  background-color: transparent !important;
  border: 0;
  padding: 0;
  width: fit-content !important;
  margin: 0;

  :hover {
    background-color: transparent !important;
    color: variable.$error-500;
    transform: rotate(360deg);
    transform-origin: center;
    transition: transform 1s cubic-bezier(1, 0.82, 0.165, 1);
  }
}
