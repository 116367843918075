@use '/src/styles/base/_variables.scss' as variable;
@use '/src/styles/mixins/_layout' as layout;
@use '/src/styles/mixins/_breakpoints' as breakpoint;
@use '/src/styles/base/typography' as typography;

.role-ability-cards-container {
  @include layout.flex($justify: center);
  gap: 1.25rem;
  flex-wrap: wrap;

  .role-ability-card {
    padding: 0;
    width: 100%;
    border: 0;

    &.can-do {
      .role-icon {
        color: variable.$icon-icon-success;
      }
    }

    &.cannot-do {
      .role-icon {
        color: variable.$error-500;
      }
    }

    .role-body {
      @include layout.flex();
      flex-direction: column;
      gap: 0.75rem;
      margin-top: 1rem;

      .role {
        @include layout.flex($align: center, $justify: center);
        @extend .sm;
        gap: 0.75rem;

        .role-icon {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }
}
