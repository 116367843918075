@use '/src/styles/base/variables' as variable;
@use '/src/styles/base/typography' as typography;
@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;

.team-management-tab {
  @include breakpoint.media('<desktop') {
    .table-container table {
      min-width: 100%;
    }
  }
}

.team-management-table {
  .document-type-field {
    @include layout.flex($align: center);
    gap: 0.22rem;
    width: 10rem;

    .role-description div {
      @include layout.flex($align: center, $justify: center);
    }
  }

  .add-role-button {
    background-color: variable.$info-200;
    color: variable.$black;
    @extend .xxs;
    .icon {
      width: 01.22rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .permissions-field {
    width: 4rem;
  }

  @include breakpoint.media('<tablet') {
    .field-title {
      width: min-content;
    }
  }
}
