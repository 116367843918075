@use '/src/styles/mixins/layout' as layout;
@use '/src/styles/mixins/breakpoints' as breakpoint;
@use '/src/styles/base/typography' as typography;
@use '/src/styles/base/variables' as variable;
@use '../../business/wallet/_fund-wallet.scss' as fund-wallet-style;

.create-role-form {
  @extend .fund-wallet-form;
  height: fit-content;

  .title {
    @extend .sm;
    font-weight: bold;
  }

  .input-container {
    margin-top: 1.0567rem;
  }

  .select-role-section {
    padding-top: 2.22rem;
    .title {
      margin-bottom: 1.5rem;
    }

    .select-role-fields {
      @include layout.flex();
      flex-direction: column;
      gap: 1.667rem;
      width: 100%;

      .role-label {
        color: variable.$black;
      }
    }
  }
}


