@use '/src/styles/base/variables.scss' as variable;
@use '/src/styles/mixins/layout.scss' as layout;

.styled-checkbox-container {
  display: flex;
  align-items: flex-start;
  gap: 0.375rem;

  .styled-checkbox-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 1.11rem;
    margin-top: 0.05rem;
    height: 1.11rem;
    pointer-events: none;
  }
}

.styled-checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-size: 0.75rem;
    color: variable.$text-text-primary;
    @include layout.flex($align: center);
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 0.667rem;
    display: inline-block;
    vertical-align: text-top;
    border-radius: variable.$input-radius;
    min-width: 1rem;
    height: 1rem;
    background: variable.$grey-300;
  }

  // Box hover
  &:hover + label:before {
    background: variable.$stability-col;
  }

  // Box checked
  &:checked + label:before {
    background: variable.$stability-col;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 0.278rem;
    background: white;
    width: 0.11rem;
    height: 0.11rem;
    box-shadow:
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }

  &[data-variant='no-fill'] {
    & + label:before {
      background: transparent;
      border: 0.0567rem solid variable.$grey-300;
    }

    &:checked + label:before {
      background: transparent;
      border: 0.0567rem solid variable.$info-500;
    }

    &:checked + label:after {
      background: transparent;
      box-shadow:
        2px 0 0 variable.$info-500,
        4px 0 0 variable.$info-500,
        4px -1.5px 0 variable.$info-500,
        4px -3.5px 0 variable.$info-500,
        4px -5.5px 0 variable.$info-500;
    }
  }
}
